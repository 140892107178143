<template>
    <!--编辑银行账户-->
  <div class="bank-account-container">
    <znl-dialog :title="title"
      :visible="visible"
      height="510px"
      width="690px"
      class="dialog-bankaccount-add-box"
      v-loading="dialogLoading"
      :element-loading-text="LoadingText"
      :close-on-click-modal="false"
      @close="()=>{$emit('close')}">

      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.CBNo"
              title="账号："
              :is-must-fill="true"
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-checkbox v-model="isAccountForbidden"
              style="margin-left: 80px;"> 禁用账户</el-checkbox>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.CBName"
              title="账户名称："
              :is-must-fill="true"
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="isAdd">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.Amount"
              title="期初余额："
              :is-must-fill="true"
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.BCode"
              title="银行编码："
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.BTelephone"
              title="银行电话："
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.BFax"
              title="银行传真："
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.BAddress"
              width="100%"
              title="银行地址："
              title-width="80px"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <znl-input :border="true"
              v-model="bankAccount.ManagerGUID"
              title-width="80px"
              form-type="select"
              title="账户管理人："
              :clearable="true"
              :select-options="managerOptions"
              placeholder="-请选择管理人-">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark bank-account-remark">
            <znl-input :border="true"
              width="100%"
              title-width="80px"
              title="备注："
              v-model="bankAccount.Remark"
              type="textarea"
              placeholder="">
            </znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark bank-account-explain">
            这里编辑账户的基本信息，要设置账户的
            <span style="color:#00BCD4">“期初余额”</span>请在此账户里的
            <span style="color:#00BCD4">“收支流水”</span>里添加。
          </div>
        </el-col>
      </el-row>
      <el-row align="middle"
        type="flex"
        justify="end"
        slot="footer"
        class="dialog-footer">
        <el-col style="justify-content: flex-end;align-items: center">
          <znl-button :height="30"
            :width="60"
            @click="onClear"> 清空</znl-button>
          <znl-button @click="onSave"
            :height="30"
            :width="60"
            style-type="main" v-loading="saveloading">保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  bankAccountAddURL: 'FinBankAccount/Add',
  bankAccountEditURL: 'FinBankAccount/Edit',
  bankAccountGetURL: 'FinBankAccount/Get'
}

export default {
  name: 'FinBankAccountEdit',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  components: {},
  data () {
    return {
      dialogLoading: false,
      LoadingText: '',
      managerOptions: [],
      isAccountForbidden: false,
      bankAccount: {
        CBID: '', // 账户ID
        CBGUID: '', // 账户GUID
        CBNo: '', // 账户编号
        CBName: '', // 账户名称
        Currency: '', // 账户币种
        BCode: '', // 银行编码
        BAddress: '', // 银行地址
        BTelephone: '', // 银行电话
        BFax: '', // 银行传傎
        IsFrozen: '', //
        ManagerGUID: '', // 账户管理人GUID
        ManagerName: '', // 账户管理人
        Amount: '', // 账户金额
        Remark: '', // 备注
        CompanyID: '', // 公司ID
        IsActive: '', // 启用状态（1，禁用 2.启用 3.删除）
        CreatedTime: '', // 创建时间
        CreatedByID: '', // 创建人ID
        CreatedByName: '' // 创建人
      },
      saveloading:false,
      isAdd: false
    }
  },
  props: {
    title: String,
    visible: Boolean
  },
  watch: {},
  methods: {
    onInit: async function () {
      // 创建人
      let account = _.map(await this.selectOptionsAllAccount, opt => {
        return {
          key: opt.key,
          value: opt.value
        }
      })
      this.managerOptions = account

      let pdata = this.$store.state.paramOrder.bankAccountRow
      if (pdata) {
        this.onSetValue(pdata)
      }
      this.isAdd = !this.hasValue(this.bankAccount.CBID) || !this.hasValue(this.bankAccount.CBGUID)
    },
    onSetValue (obj) {
      if (obj) {
        this.bankAccount.CBID = obj.CBID
        this.bankAccount.CBGUID = obj.CBGUID
        this.bankAccount.CBNo = obj.CBNo
        this.bankAccount.CBName = obj.CBName
        this.bankAccount.Currency = obj.Currency
        this.bankAccount.BCode = obj.BCode
        this.bankAccount.BAddress = obj.BAddress
        this.bankAccount.BTelephone = obj.BTelephone
        this.bankAccount.BFax = obj.BFax
        this.bankAccount.IsFrozen = obj.IsFrozen
        this.bankAccount.ManagerGUID = obj.ManagerGUID
        this.bankAccount.ManagerName = obj.ManagerName
        this.bankAccount.Amount = obj.Amount
        this.bankAccount.Remark = obj.Remark
        this.bankAccount.CompanyID = obj.CompanyID
        this.bankAccount.IsActive = obj.IsActive
        this.bankAccount.CreatedTime = obj.CreatedTime
        this.bankAccount.CreatedByID = obj.CreatedByID
        this.bankAccount.CreatedByName = obj.CreatedByName

        this.isAccountForbidden = (obj.IsActive === 1)
      }
    },
    onClear () {
      this.bankAccount.CBID = ''
      this.bankAccount.CBGUID = ''
      this.bankAccount.CBNo = ''
      this.bankAccount.CBName = ''
      this.bankAccount.Currency = ''
      this.bankAccount.BCode = ''
      this.bankAccount.BAddress = ''
      this.bankAccount.BTelephone = ''
      this.bankAccount.BFax = ''
      this.bankAccount.IsFrozen = ''
      this.bankAccount.ManagerGUID = ''
      this.bankAccount.ManagerName = ''
      this.bankAccount.Amount = ''
      this.bankAccount.Remark = ''
      this.bankAccount.CompanyID = ''
      this.bankAccount.IsActive = ''
      this.bankAccount.CreatedTime = ''
      this.bankAccount.CreatedByID = ''
      this.bankAccount.CreatedByName = ''
    },
    async onSave () {
      if (this.CheckData()) {
        this.saveloading = true;
        let vm = this
        if (!this.hasValue(this.bankAccount.CBID) || !this.hasValue(this.bankAccount.CBGUID)) {
          this.$post(CONFIG.bankAccountAddURL, this.bankAccount, (data, logic) => {
            if (data) {
              this.$message({ message: '保存成功', type: 'success' })
              vm.$emit('confirm', true)
            } else {
              this.$message({ message: '保存失败！' +  logic.msg, type: 'error' })
            }
             this.saveloading = false;
          })
        } else {
          this.$post(CONFIG.bankAccountEditURL, this.bankAccount, (data, logic) => {
            if (data) {
              this.$message({ message: '保存成功', type: 'success' })
              vm.$emit('confirm', true)
            } else {
              this.$message({ message: '保存失败！' +  logic.msg, type: 'error' })
            }
             this.saveloading = false;
          })
        }
      }
    },
    CheckData () {
      if (!this.hasValue(this.bankAccount.CBName)) {
        this.$message({ message: '账户名称不能为空', type: 'error' })
        return false
      }
      if (!this.hasValue(this.bankAccount.CBNo)) {
        this.$message({ message: '账号不能为空', type: 'error' })
        return false
      }
      this.bankAccount.IsActive = this.isAccountForbidden ? 1 : 2
      return true
    },
    hasValue (val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    }
  },
  async created () {
    this.$nextTick(() => {
      this.onInit()
    })
  }
}
</script>

<style lang="scss">
.bank-account-container {
  padding: 30px;
  .el-row {
    padding: 3px 15px;
    .bank-account-remark {
      height: 45px;
    }
    .bank-account-explain {
      margin-left: 80px;
      color: #9a9999;
      font-size: 12px;
    }
  }
}
</style>

<template>
  <!--编辑收支明细-->
  <znl-dialog
    :visible="visible"
    :title="title"
    v-loading="dialogLoading"
    :element-loading-text="LoadingText"
    height="370px"
    width="600px"
    class="dialog-fininandexpdetails-add-box"
    :close-on-click-modal="false"
    @close="()=>{$emit('close')}"
  >
    <div slot="znl-dialog" class="row-box">
      <div>
        <el-row type="flex">
          <!--第一列-->
          <el-col :span="11">
            <el-row class="form_Row_W100">
              <znl-input
                form-type="select"
                type="text"
                placeholder="收支类型"
                width="100%"
                title-width="70px"
                size="mini"
                layout="left"
                v-model="details.BillType"
                :select-options="dataMap"
                title="收支类型："
                :disabled="true"
                :border="true"
                :is-must-fill="true"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                placeholder="账户"
                width="100%"
                title-width="70px"
                size="mini"
                layout="left"
                v-model="details.BankAccountNo"
                type="text"
                title="账号："
                :disabled="true"
                :border="true"
                :is-must-fill="false"
              ></znl-input>
            </el-row>

            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                type="text"
                placeholder="单号"
                width="100%"
                title-width="70px"
                size="mini"
                layout="left"
                v-model="details.SourceNo"
                title="单号："
                :disabled="true"
                :border="true"
                :is-must-fill="true"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                placeholder="支出金额"
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="支出金额："
                width="100%"
                title-width="70px"
                :clearable="true"
                :border="true"
                v-model="details.Expenditure"
                :disabled="disableExpenditure"
                type="text"
              ></znl-input>
            </el-row>
          </el-col>

          <!--第二列 -->
          <el-col :span="11" :offset="1">
            <el-row class="form_Row_W100">
              <znl-input
                form-type="datepicker"
                placeholder="收支日期"
                width="100%"
                title-width="70px"
                size="mini"
                layout="left"
                :is-must-fill="false"
                :border="true"
                v-model="details.BusinessDate"
                title="收支日期："
                :disabled="true"
              ></znl-input>
            </el-row>

            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                type="text"
                placeholder="公司名称"
                width="100%"
                size="mini"
                layout="left"
                v-model="details.CompanyName"
                title="公司名称："
                title-width="70px"
                :disabled="true"
                :border="true"
                :is-must-fill="true"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                placeholder="收入金额"
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="收入金额："
                width="100%"
                title-width="70px"
                :clearable="true"
                :border="true"
                v-model="details.InCome"
                :disabled="disableInCome"
                type="text"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                placeholder="折扣金额"
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="折扣金额："
                title-width="70px"
                width="100%"
                :clearable="true"
                :border="true"
                v-model="details.DiscountAmount"
                type="text"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                type="textarea"
                :rows="5"
                placeholder="摘要"
                width="100%"
                size="mini"
                layout="left"
                v-model="details.Abstract"
                title="摘要："
                title-width="70px"
                :disabled="true"
                :border="true"
                :is-must-fill="false"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row align="middle" type="flex" justify="end" slot="footer">
      <el-col style="display: flex;justify-content: flex-end;align-items: center">
        <znl-button
          @click="Save"
          :height="30"
          :width="60"
          :disabled="dialogLoading"
          style-type="main"
        >保存</znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>
<script>
const CONFIG = {
  searchById: "FinInAndExpDetails/GetModelById",
  saveURL: "FinInAndExpDetails/Edit"
};

export default {
  name: "FinCapitalFlowDetailsAdd",
  components: {},
  data() {
    return {
      details: {
        // 对象
        RPGUID: "",
        BankAccountNo: "", // 账号
        BusinessDate: "",
        SourceNo: "", // 单号
        CompanyName: "",
        BillType: 0,
        BillTypeStr: "",
        InCome: 0,
        Expenditure: 0,
        DiscountAmount: 0,
        Abstract: ""
      },
      BillTypeOptions: [],
      disableInCome: false,
      disableExpenditure: false,
      dialogLoading: false,
      LoadingText: "",
      dataMap: [
        { key: 1, value: "销售收入" },
        { key: 2, value: "采购支出" },
        { key: 3, value: "其它收入" },
        { key: 4, value: "其它支出" },
        { key: 5, value: "期初余额" },
        { key: 6, value: "预收款" },
        { key: 7, value: "预付款" }
        // { 'key': 8, 'value': '预收款冲销' },
        // { 'key': 9, 'value': '预付款冲销' }
      ]
    };
  },
  props: {
    RPDetails: Object, // GUID
    isRefresh: Boolean, // 是否刷新
    visible: Boolean,
    title: String
  },
  watch: {
    RPDetails: {
      handler(RPDetails) {
        // this.LoadPage(RPDetails)
      },
      immediate: true
    },
    isRefresh: {
      handler(value) {
        this.onResetPage();
        this.LoadPage(this.RPDetails);
      },
      immediate: true
    }
  },
  methods: {
    LoadPage(data) {
      if (data) {
        let passData = JSON.stringify(data);
        this.details = JSON.parse(passData);
        if (this.details.BillType === 2) {
          this.disableInCome = true;
          this.disableExpenditure = false;
        }
        if (this.details.BillType === 1) {
          this.disableInCome = false;
          this.disableExpenditure = true;
        }
      }
    },
    transformSubmitValue(requestData) {
      // name => key 数据转换
      let items = requestData;
      _.each(items, item => {
        if (_.isFinite(item)) {
          item = Number(item);
        }
      });
      _.each(this.dataMap, data => {
        if (data.value === items.BillType) {
          items.BillType = data.key;
        }
      });
      return items;
    },
    Save() {
      // 保存
      if (this.CheckData()) {
        this.dialogLoading = true;
        this.LoadingText = "正在保存，请稍等...";
        this.$post(CONFIG.saveURL, this.details, datas => {
          this.dialogLoading = false;
          if (datas > 0) {
            this.$message({ message: "保存成功", type: "success" });
            this.$emit("confirm", true);
          }
        });
      }
    },
    CheckData() {
      // 检查数据的正确性
      let ret = true;
      this.details = this.transformSubmitValue(this.details);
      if (this.details.BillType === "") {
        ret = false;
        this.$message({ message: "没有设置收支类型", type: "error" });
        // } else if (_.isNaN(Number(this.details.InCome)) || _.isNaN(Number(this.details.Expenditure)) || _.isNaN(Number(this.details.DiscountAmount))) {
        //   ret = false
        //   this.$message({message: '金额必须为数字类型', type: 'error'})
      } else if (
        this.details.BillType === 1 &&
        Number(this.details.InCome) < 0
      ) {
        ret = false;
        this.$message({ message: "收入金额不能小于零!", type: "error" });
      } else if (
        this.details.BillType === 2 &&
        Number(this.details.Expenditure) < 0
      ) {
        ret = false;
        this.$message({ message: "支出金额不能小于零!", type: "error" });
      }
      return ret;
    },
    onResetPage() {
      this.details.RPGUID = "";
      this.details.BankAccountNo = ""; // 账号
      this.details.BusinessDate = "";
      this.details.SourceNo = ""; // 单号
      this.details.CompanyName = "";
      this.details.BillType = "";
      this.details.BillTypeStr = "";
      this.details.InCome = "";
      this.details.Expenditure = "";
      this.details.DiscountAmount = "";
      this.details.Abstract = "";
    }
  },
  created() {
    let billtype = [
      { key: 1, value: "销售收入" },
      { key: 2, value: "采购支出" }
    ];
    this.BillTypeOptions = billtype;
  }
};
</script>

<style lang="scss">
.dialog-fininandexpdetails-add-box {
  .form_Row_W100 {
    padding: 5px 0;
  }
}
</style>

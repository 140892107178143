<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
    <finRecInAdvance-view
      ref="recDetail"
      v-if='isShowDetail'
      slot="znlLayoutSpa"
      class="znlLayoutSpa"
      @page-loading="(d)=>{pageLoading=d}"
      @to-total="searchTotal">
    </finRecInAdvance-view>
    <FinRecInAdvanceTotal
      ref="recTotal"
      v-if='!isShowDetail'
      slot="znlLayoutSpa"
      class="znlLayoutSpa"
      @page-loading="(d)=>{pageLoading=d}"
      @to-detail="searchDetail">
    </FinRecInAdvanceTotal>
  </znl-layout-spa>
</template>

<script>
import FinRecInAdvanceView from '@/components/modules/Fin/FinRecInAdvance_v1'
import FinRecInAdvanceTotal from '@/components/modules/Fin/FinRecInAdvanceTotal'

export default {
  name: 'FinRecInAdvance',
  components: {
    FinRecInAdvanceView,
    FinRecInAdvanceTotal
  },
  data () {
    return {
      //pageData: [],
      pageLoading: true,
      isShowDetail: true
    }
  },
  methods: {
    searchTotal() {
      this.isShowDetail = false
      this.$nextTick(() => {
        this.$refs.recTotal.search()
      })     
    },
    searchDetail(companyName) {
      this.isShowDetail = true
      this.$nextTick(() => {
        this.$refs.recDetail.search(companyName)
      })    
    } 
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <!-- 预付款 -->
  <div class="module-wrapper m-fin-pay-in-advance">
    <znl-table
      ref="table"
      gridtype="base"
      :showIndex="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      v-loading="loading"
      box-class="m-fin-pay-in-advance"
      element-loading-text="数据加载中,请稍后..."
      :totalField="['Expenditure']"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FinPayInAdvance')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            width="260px"
            title="账户："
            title-width="40px"
            inp-perc="100%"
            :border="true"
            @change="selectBankName"
            :select-options="selectOptionsBankAccountAndName"
            placeholder="--请选择预付款账户--"
            popper-class="popperClass"
            v-model="filterFields.BankAccountGUID"
            :clearable="true"
          ></znl-input>

          <znl-input
            :border="true"
            v-model="filterFields.CompanyName"
            placeholder="请输入公司名称"
            title="公司名称："
            title-width="70px"
            width="260px"
            :clearable="true"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            width="190px"
            title-width="80px"
            title="收款日期从："
            placeholder=" - - "
            size="mini"
            layout="left"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.startDate"
          ></znl-input>
          <span class="to">到</span>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            placeholder=" - - "
            size="mini"
            layout="left"
            width="110px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.endDate"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-input
            :border="true"
            v-model="filterFields.Abstract"
            placeholder="请输入备注"
            title="备注："
            title-width="40px"
            width="260px"
            :clearable="true"
          ></znl-input>

          <znl-button
            type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn ml5"
          >
            <znl-button-menu @click="SearchGridList">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
          </znl-button>

          <!-- 新增 -->
          <znl-button
            style-type="mac"
            :height="22"
            @click="finPayinAdd()"
            v-if="this.hasRes('Add')"
          >
            <span>
              <i class="iconfont icon-add_btn_ic"></i>增加预付款
            </span>
          </znl-button>

          <znl-button
            style-type="mac"
            :btns="printznlbtns"
            :height="22"
            v-if="this.hasRes('Export')"
            @click="onExport"
            tip="导出"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>
          <znl-button
            style-type="mac"
            :height="22"
            @click="toTotal"
          >
            <span>预付汇总</span>
          </znl-button>
        </el-row>
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24" class="money-summary">
            <span>
              总金额：
              <span class="number">{{ summary.Outgoings }}</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>
    <fin-payin-add
      v-if="finPayinAddVisible"
      :visible="finPayinAddVisible"
      :title="finPayInTitle"
      ref="FinPayinAdd"
      :RPGUID="RPGUID"
      :isRefresh="onrefreshAdd"
      @close="()=>{finPayinAddVisible=false}"
      @confirm="(val)=>{val&&(finPayinAddVisible=false),onBindData(pageIndex)}"
    ></fin-payin-add>

    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>

<script>
import finPayinAdd from "@c_modules/Fin/FinPayinAdd";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

import ExportColumn from "@c_modules/Crm/setExportColumn";
import {
  exportData,
  erpTableSetHandler,
  dateTimeFormat
} from "@scripts/methods/common";
import index from "vue";

const CONFIG = {
  PK: "RPGUID",
  configURL: "FinPayInAdvance/GetConfig",
  saveConfigURL: "FinPayInAdvance/SaveConfig",
  searchURL: "FinPayInAdvance/Search",
  addURL: "FinPayInAdvance/Add",
  updateURL: "FinPayInAdvance/Edit",
  multiDeleteURL: "FinPayInAdvance/MultiDel",
  getAdvanceSummaryURL: "FinPayInAdvance/GetAdvanceSummary"
};

export default {
  name: "FinPayInAdvance",
  mixins: [exportData, getCommonDataMixin, erpTableSetHandler, dateTimeFormat],
  config: CONFIG,
  components: {
    finPayinAdd,
    ExportColumn
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    refreshBank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogCompanySelectVisible: false,
      RPGUID: "",
      finPayInTitle: "新增预付款",
      columns: [],
      defaultConfig: {},
      itemSource: [],
      searchFields: {},
      filterFields: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
        endDate: "",
        CompanyName: "",
        Abstract: "",
        BankAccountGUID: null
      },
      pageSize: 20000,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      finPayinAddVisible: false, // 弹窗出现
      onrefreshAdd: true, // 刷新添加页面
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: "",
      searchCondition: {},
      loading: false,
      summary: {
        BeginBalance: 0,
        EndBalance: 0,
        InCome: 0,
        Outgoings: 0
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("finPayInAdvance", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let tempConfig = [
        {
          width: 50,
          type: "button",
          title: "删除",
          btnTxt: "删除", // this.hasRes('Delete')
          visible: true,
          isDeal: false,
          click: r => {
            this.onDelete(r);
          }
        },
        {
          width: 50,
          type: "button",
          title: "编辑",
          btnTxt: "编辑", // this.hasRes('Add')
          visible: true,
          isDeal: false,
          click: r => {
            this.finPayinEdit(r);
          }
        }
      ];

      let columns = config.ColumnConfigs;
      columns = tempConfig.concat(columns);

      this.columns = columns;
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.defaultConfig = config;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$emit("page-loading", false);
      //let vm = this
      //vm.getTotal()
    },

    async onBindData(pageIndex = 1) {
      this.loading = true;
      let filterFields = {};
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[
            key.substring(0, 1).toUpperCase() + key.substring(1)
          ] = value;
        });
      }
      this.searchCondition = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          searchType: 3,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        filterFields
      );
      let response = await this.$post(
        this.$options.config.searchURL,
        this.searchCondition
      );
      this.itemSource = response.ResultList;
      this.totalCount = response.TotalCount;
      setTimeout(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      }, 500);
      if (pageIndex == 1) {
        this.getTotal();
      }
    },
    SearchGridList() {
      this.loading = true;

      if (!this.hasValue(this.filterFields.CompanyName) &&　!this.hasValue(this.filterFields.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        this.loading = false;
        return false;
      }

      this.onBindData(1);
    },
    search(companyName) {
      if (companyName) {
        this.filterFields.CompanyName = companyName
        this.filterFields.startDate = null
        this.filterFields.endDate = null
        this.SearchGridList()
      }
    },
    selectBankName(obj) {
      if (this.hasValue(obj)) {
        this.searchFields.BankAccountGUID = obj.key;
      } else {
        this.searchFields.BankAccountGUID = null;
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    finPayinAdd() {
      this.RPGUID = "";
      this.finPayInTitle = "新增预付款";
      this.onrefreshAdd = !this.onrefreshAdd;
      this.finPayinAddVisible = true; // 弹窗是否出现
    },
    finPayinEdit(row) {
      this.finPayInTitle = "编辑预付款";
      this.onrefreshAdd = !this.onrefreshAdd;
      this.RPGUID = row.RPGUID;
      this.finPayinAddVisible = true; // 弹窗是否出现
    },

    async onDelete(row) {
      var rows = [row];
      if (rows.length < 1) {
        return this.$message({
          message: "请选择要删除的数据",
          type: "error"
        });
      }

      let dateStr = this.formatDate(row.BusinessDate);
      let msg = `您确定删除公司【${row.CompanyName}】金额【${row.Expenditure}】时间【${dateStr}】的这条记录？`;

      let isok = await this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });

      if (isok) {
        let vm = this;
        this.$post(
          CONFIG.multiDeleteURL,
          _.filter(rows, row => row[CONFIG.PK]),
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.onBindData();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }
        );
      }

      // this.$confirm('确认删除【' + rows.length + '】条预付款？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   return this.$post(
      //     CONFIG.multiDeleteURL,
      //     _.filter(rows, row => row[CONFIG.PK]),
      //     data => {
      //       if (data > 0) {
      //         this.$message({ message: '删除成功', type: 'success' })
      //         this.onBindData()
      //       } else {
      //         this.$message({ message: '删除失败', type: 'error' })
      //       }
      //     }
      //   )
      // })
    },
    async onExport() {
      if (!this.hasValue(this.filterFields.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        return false;
      }
      if (
        this.DateDiffer(this.filterFields.startDate, this.filterFields.endDate)
      ) {
        this.$message({
          message: "为避免加载过慢,系统已限制一次只能导出时间段为一年的数据!",
          type: "error"
        });
        return false;
      }
      this.loading = true;
      this.loading = await this.exportData(
        "FinPayInAdvance",
        this.searchCondition,
        this.columns,
        "预付款"
      );
    },

    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setConfigColumns = this.defaultConfig;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    toTotal() {
      this.$emit("to-total")
    },
    // 获取合计数据
    getTotal() {
      if (this.pageIndex === 1) {
        this.$post(CONFIG.getAdvanceSummaryURL, this.searchCondition, data => {
          this.summary = data;
        });
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FinPayInAdvance", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("FinPayInAdvance", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("FinPayInAdvance", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FinPayInAdvance");
    }
  },
  watch: {
    async refreshBank() {}
  }
};
</script>

<style lang="scss">
.m-fin-pay-in-advance {
  height: 100%;
  .search-box {
    .znl-input-title {
      padding-left: 5px;
      text-align: right;
    }
    .to {
      padding: 0 3px;
    }
  }
  .ml5 {
    margin-left: 5px;
  }
  .money-summary {
    span {
      margin-right: 12px;
      .number {
        color: rgb(255, 0, 0);
      }
    }
  }
}
</style>

<template>
  <!-- 银行账户 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :showIndex="true"
      :columns="columns"
      :item-source="itemSource"
      @on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FinBankAccount')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="请输入账户名称"
          width="130px"
          size="mini"
          layout="left"
          v-model="CBName"
          type="text"
          :clearable="true"
          :border="true"
          @keyup.enter.native="onBindData()"
        ></znl-input>
        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onBindData()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
        <!-- 新增 -->
        <znl-button style-type="mac" :height="22" @click="addrow()" v-if="this.hasRes('Add')">
          <span>
            <i class="iconfont icon-add_btn_ic"></i>增加账户
          </span>
        </znl-button>
      </div>
    </znl-table>
    <bank-account-edit
      v-if="bankAccountEditVisible"
      :visible="bankAccountEditVisible"
      title="编辑现金银行账户"
      @close="()=>{bankAccountEditVisible=false}"
      @confirm="onEditConfirm"
    ></bank-account-edit>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { erpTableSetHandler } from '~assets/scripts/methods/common'
import bankAccountEdit from '@c_modules/Fin/FinBankAccountEdit'

const CONFIG = {
  PK: 'CBGUID',
  configURL: 'FinBankAccount/GetConfig',
  searchURL: 'FinBankAccount/Search',
  addURL: 'FinBankAccount/Add',
  updateURL: 'FinBankAccount/Edit',
  saveRowsURL: 'FinBankAccount/BatchEdit',
  deleteURL: 'FinBankAccount/Del',
  multiDeleteURL: 'FinBankAccount/MultiDel'
}

export default {
  name: 'FinBankAccount',
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    bankAccountEdit
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      CBName: '',
      searchModelCom: { CBName: '' },
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      bankAccountEditVisible: false,
      account: [],
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  computed: {},
  async created() {
    // 经手人
    this.account = await this.selectOptionsAllAccount
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('finBankAccount', CONFIG.configURL)
      }
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      let tempConfig = [
        {
          width: 50,
          type: 'button',
          title: '删除',
          btnTxt: '删除', // this.hasRes('Delete')
          visible: true,
          isDeal: false,
          click: r => {
            this.onDelete(r)
          }
        },
        {
          width: 50,
          type: 'button',
          title: '编辑',
          btnTxt: '编辑', // this.hasRes('Add')
          visible: true,
          isDeal: false,
          click: r => {
            this.BankAccountEdit(r)
          }
        }]

      let columns = config.ColumnConfigs
      // 状态
      let colIndex = _.findIndex(columns, item => item.BindField === 'IsActive')
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: 'select',
          width: 70,
          options: [{ key: '禁用', value: 1 }, { key: '可用', value: 2 }],
          isReadOnly: true
        })
      }
      columns = tempConfig.concat(columns)

      this.columns = columns
      this.parameterConfig = config.ParamJosn
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$emit('page-loading', false)
    },
    onSearch(params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          CBName: this.CBName
        }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        _.each(data.ResultList, item => {
          if (item.ManagerGUID === undefined) {
            item.ManagerGUID = null
          }
        })
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        this.$emit('page-loading', false)
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    },
    async onDelete(row) {
      let msg = `删除账户同时会删除该账户下的资金流水，您确定要删除账号为【${row.CBNo}】的账户?`
      let isok = await this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

      if (isok) {
        this.$post(CONFIG.deleteURL, row, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.onSearch()
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
      }
    },
    BankAccountEdit(row) {
      if (row) {
        this.$store.commit('setParamOrder', { 'bankAccountRow': row })
      }
      this.bankAccountEditVisible = !this.bankAccountEditVisible
    },
    hasRes(code) {
      return _.includes(this.resourceList, code)
    },
    async onRefresh() {
      // this.columns = this.clearcolumnscolor(this.columns)
      await this.onBindData(this.pageIndex)
    },
    async onEditConfirm(val) {
      if (val) {
        this.bankAccountEditVisible = false
        this.onRefresh()
      }
      await this.getBankAccount()
      this.$emit('on-bank-changed', true)
    },
    addrow() {
      this.$store.commit('setParamOrder', { 'bankAccountRow': null })
      this.bankAccountEditVisible = !this.bankAccountEditVisible
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FinBankAccount", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("FinBankAccount", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("FinBankAccount", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FinBankAccount")
    }
  }
}
</script>

<style lang="scss">
</style>

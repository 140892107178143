<template>
  <!-- 收支明细 -->
  <div class="module-wrapper m-fin-bank-account">
    <znl-table
      ref="table"
      gridtype="base"
      :showIndex="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      box-class="m-fin-bank-account"
      :totalField="['InCome','Expenditure']"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FinInAndExpDetails')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            layout="left"
            form-type="select"
            title="账户："
            title-width="40px"
            :disabled="false"
            width="260px"
            inp-perc="100%"
            placeholder="-请选择现金银行账户-"
            @change="selectBankName"
            :select-options="selectOptionsBankAccountAndName"
            popper-class="popperClass"
            :border="true"
            v-model="fieldsFilter.BankAccountGUID"
            :clearable="true"
          ></znl-input>

          <znl-input
            :border="true"
            v-model="fieldsFilter.CompanyName"
            placeholder="请输入公司名称"
            title="公司名称："
            title-width="70px"
            width="260px"
            :clearable="true"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            title-width="80px"
            title="记账日期从："
            placeholder=" - - "
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="190px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="fieldsFilter.startDate"
          ></znl-input>
          <span class="to">到</span>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder=" - - "
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="110px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="fieldsFilter.endDate"
          ></znl-input>

          <znl-input
            :border="true"
            v-model="fieldsFilter.BillType"
            placeholder="-请选择收支流水类型-"
            title="收支类型："
            title-width="60px"
            width="200px"
            form-type="select"
            :select-options="billTypeOptions"
            @change="onBillTypeSelectedChange"
            tabindex="4"
            :clearable="true"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-input
            :border="true"
            title="单号："
            title-width="40px"
            width="260px"
            v-model="fieldsFilter.SourceNo"
            :clearable="true"
            placeholder="请输入单号"
          ></znl-input>
          <znl-input
            :border="true"
            title="摘要："
            title-width="70px"
            width="260px"
            align="right"
            v-model="fieldsFilter.Abstract"
            :clearable="true"
            placeholder="请输入摘要"
          ></znl-input>

          <znl-button
            type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn ml5"
          >
            <znl-button-menu @click="SearchGridList">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
          </znl-button>
          <znl-button
            style-type="mac"
            :btns="printznlbtns"
            :height="22"
            v-if="this.hasRes('Export')"
            @click="onExport"
            tip="按搜索条件"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>
        </el-row>
      </div>
    </znl-table>
    <fin-inexp-details-add
      ref="FinInexpDetailsAdd"
      :RPDetails="RPDetails"
      title="收支明细编辑"
      v-if="finInAndExpAddVisible"
      :visible="finInAndExpAddVisible"
      @close="finInAndExpAddVisible=false"
      :isRefresh="onrefreshAdd"
      @confirm="(val)=>{val&&(finInAndExpAddVisible=false),onBindData(pageIndex)}"
    ></fin-inexp-details-add>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>

<script>
import FinInexpDetailsAdd from "@c_modules/Fin/FinInAndExpDetailsAdd";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  PK: "RPLineID",
  configURL: "FinInAndExpDetails/GetConfig",
  saveConfigURL: "FinInAndExpDetails/SaveConfig",
  multiDeleteURL: "FinInAndExpDetails/MultiDel",
  searchURL: "FinInAndExpDetails/Search"
};

export default {
  name: "FinInAndExpDetails",
  mixins: [exportData, getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    FinInexpDetailsAdd,
    ExportColumn
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    refreshBank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [],
      defaultConfig: {},
      itemSource: [],
      fieldsFilter: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
        endDate: "",
        CompanyName: "",
        BillType: null,
        Abstract: "",
        SourceNo: "",
        BankAccountGUID: null
      },
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      finInAndExpAddVisible: false,
      RPDetails: {},
      onrefreshAdd: true, // 刷新添加页面
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: "",
      searchCondition: {},
      loading: false,
      billTypeOptions: [
        { key: 1, value: "销售收入" },
        { key: 2, value: "采购支出" }
        // { key: 3, value: '其它收入' },
        // { key: 4, value: '其它支出' },
        // { key: 5, value: '期初余额' },
        // { key: 6, value: '预收款' },
        // { key: 7, value: '预付款' }
      ],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage(
          "finInAndExpDetails",
          CONFIG.configURL
        );
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let tempConfig = [
        {
          width: 50,
          type: "button",
          title: "删除",
          btnTxt: "删除", // this.hasRes('Delete')
          visible: true,
          isDeal: false,
          click: r => {
            this.onDelete(r);
          }
        },
        {
          width: 50,
          type: "button",
          title: "编辑",
          btnTxt: "编辑", // this.hasRes('Add')
          visible: true,
          isDeal: false,
          click: r => {
            this.finInExpEdit(r);
          }
        }
      ];

      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "BillType"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 80,
          options: [
            { key: "销售收入", value: 1 },
            { key: "采购支出", value: 2 },
            { key: "其它收入", value: 3 },
            { key: "其它支出", value: 4 },
            { key: "期初余额", value: 5 },
            { key: "预收款", value: 6 },
            { key: "预付款", value: 7 }
          ]
        });
      }
      columns = tempConfig.concat(columns);

      this.columns = columns;
      this.defaultConfig = config;
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
    },
    finInExpEdit(row) {
      this.onrefreshAdd = !this.onrefreshAdd;
      this.RPDetails = row;
      this.finInAndExpAddVisible = true; // 弹窗是否出现
    },

    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      this.searchCondition = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          StartDate: this.fieldsFilter.startDate,
          EndDate: this.fieldsFilter.endDate
        },
        {
          // 外部搜索条件
        }
      );
      return this.$post(CONFIG.searchURL, this.searchCondition, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    selectBankName(obj) {
      if (this.hasValue(obj)) {
        this.fieldsFilter.BankAccountGUID = obj.key;
      } else {
        this.fieldsFilter.BankAccountGUID = null;
      }
    },
    SearchGridList() {
      if (!this.hasValue(this.fieldsFilter.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        return false;
      }
      if (
        this.DateDiffer(this.fieldsFilter.startDate, this.fieldsFilter.endDate)
      ) {
        this.$message({
          message:
            "为避免加载过慢,系统已限制一次只能查询时间段为一年的流水数据!",
          type: "error"
        });
      } else {
        this.onBindData(1);
      }
    },
    onSearch(params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      return this.onBindData(1);
    },
    onDelete(row) {
      this.$confirm("确认删除收支明细？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let data = [];
        data[0] = row;
        return this.$post(CONFIG.multiDeleteURL, data, data => {
          if (_.isNumber(data)) {
            if (data > 0) {
              this.$message({ message: "删除成功", type: "success" });
              this.onSearch();
            } else {
              this.$message({ message: "删除失败", type: "error" });
            }
          } else {
            this.$message({
              message:
                "以下单号因为处于已审核状态或出库状态,不可以删除。" + data,
              type: "error"
            });
            this.onBindData(this.pageIndex);
          }
        });
      });
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    async onExport() {
      if (!this.hasValue(this.fieldsFilter.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        return false;
      }
      if (
        this.DateDiffer(this.fieldsFilter.startDate, this.fieldsFilter.endDate)
      ) {
        this.$message({
          message: "为避免加载过慢,系统已限制一次只能导出时间段为一年的数据!",
          type: "error"
        });
        return false;
      }
      this.loading = true;
      this.loading = await this.exportData(
        "FinInAndExpDetails",
        this.searchCondition,
        this.columns,
        "收支明细"
      );
    },

    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    onBillTypeSelectedChange(sobj) {
      if (sobj) {
        this.fieldsFilter.BillType = sobj.key;
      } else {
        this.fieldsFilter.BillType = 0;
      }
      this.SearchGridList();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FinInAndExpDetails", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("FinInAndExpDetails", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("FinInAndExpDetails", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FinInAndExpDetails");
    }
  },
  watch: {
    async refreshBank() {}
  }
};
</script>

<style lang="scss">
.m-fin-bank-account {
  .search-box {
    .znl-input-title {
      padding-left: 5px;
      text-align: right;
    }
    .to {
      padding: 0 3px;
    }
  }
  .ml5 {
    margin-left: 5px;
  }
}
</style>

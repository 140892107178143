<template>
<!-- 子公司可输可选组件 -->
  <div>
    <znl-input
      form-type="input"
      :disabled="disabled"
      :readonly="true"
      :placeholder="placeholder"
      size="mini"
      layout="left"
      :clearable="true"
      :border="true"
      :title="title"
      :title-width="titleWidth"
      :is-must-fill="isMustFill"
      @keyup.native="onInputKeyup"
      @blur="onInputBulr"
      v-model="companyName"
      width="100%"
      type="text">
      <i slot="suffix"
        class="iconfont icon-edit_btn_ic"
        @click="onShowDialog"></i>
    </znl-input>

    <!-- 子公司选择，销售方 -->
    <sub-company-select
      v-if="dialogShow"
      :visible="dialogShow"
      :init-data="true"
      :title="dialogTitle"
      @close="onSelectClose"
      @confirm="onSelectConfirm"
    >
    </sub-company-select>
  </div>
</template>
<script>
import SubCompanySelect from '@c_modules/Fin/SubCompanySelect'

export default {
  name: 'company_input_optinoal',
  components: {
    SubCompanySelect
  },
  data () {
    return {
      dialogShow: false,
      companyName: '',
      isConfirm: false,
      selectedItem: {}
    }
  },

  methods: {
    onShowDialog () {
      this.dialogShow = true
    },

    onSelectConfirm (item) {
      this.dialogShow = false
      if (item) {
        this.companyName = item.CompanyName
        this.isConfirm = true
        this.selectedItem = item
      } else {
        this.selectedItem = null
        this.companyName = ''
      }
      this.$emit('confirm', item)
    },

    onSelectClose () {
      if (this.selectedItem === null) {
        this.companyName = ''
        this.$emit('confirm', null)
      }
      this.dialogShow = false
    },

    getInfo () {
      let vm = this
      this.$post('InvoiceTitleInfo/SearchSubCompany', { CompanyName: this.companyName }, data => {
        if (data && data.length === 1) {
          vm.companyName = data[0].CompanyName
          vm.onSelectConfirm(data[0])
        } else {
          this.selectedItem = null
          vm.onShowDialog()
        }
      })
    },

    async onInputKeyup (e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.target.blur() // 使文本框失去焦点，如果显示对话框后，再失去焦点，会触发dialog的close事件
        this.onShowDialog()
        return true
      }
    },

    async onInputBulr () {
      if (this.companyName === '') {
        this.onSelectConfirm(null)
      } else {
        this.getInfo()
      }
    }
  },

  props: {
    placeholder: String,
    disabled: Boolean,
    className: String,
    title: String,
    titleWidth: String,
    width: {
      type: String,
      default: () => {
        return '100%'
      }
    },
    isMustFill: Boolean,
    value: String,
    companyType: String,
    dialogTitle: String,
    openFrom: String
  },

  watch: {
    value () {
      this.companyName = this.value
    }
  }
}
</script>


<template>
   <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
      <FinPayInAdvanceV1
        v-if="isShowDetail"
        ref="payDetail"
        slot="znlLayoutSpa"
        class="znlLayoutSpa"
        @page-loading="(d)=>{pageLoading=d}"
        @to-total="searchTotal">
      </FinPayInAdvanceV1>
      <FinPayInAdvanceTotal
        v-if="!isShowDetail"
        ref="payTotal"
        slot="znlLayoutSpa"
        class="znlLayoutSpa"
        @page-loading="(d)=>{pageLoading=d}"
        @to-detail="searchDetail">
      </FinPayInAdvanceTotal>
   </znl-layout-spa>
</template>

<script>
import FinPayInAdvanceV1 from '@/components/modules/Fin/FinPayInAdvance_v1'
import FinPayInAdvanceTotal from '@/components/modules/Fin/FinPayInAdvanceTotal'
export default {
  name: 'FinPayInAdvance',
  components: {
    FinPayInAdvanceV1,
    FinPayInAdvanceTotal
  },
  data () {
    return {
      pageLoading: true,
      isShowDetail: true
    }
  },
  methods: {
    searchTotal() {
      this.isShowDetail = false
      this.$nextTick(() => {
        this.$refs.payTotal.search()
      })     
    },
    searchDetail(companyName) {
      this.isShowDetail = true
      this.$nextTick(() => {
        this.$refs.payDetail.search(companyName)
      })    
    } 
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <znl-dialog
    :visible="visible"
    width="800px"
    height="500px"
    class="znl_log_grid dialog-grid-footer-box"
    title="单据详情"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="base"
      :columns="columns"
      height="100%"
      layout-type="other"
      :grid-show="gridShow"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :is-multi-rows-check="false"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :users="$store.state.users"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-before-add-row="onBeforeAddRow"
      :on-page-changed="onBindData"
      @page-loading="d=>{$emit('page-loading', d)}"
      @selection-changed="d=>{$emit('selection-changed',d)}"
    ></znl-gridmodule>
    <el-row slot="footer">
      <el-col>
        <znl-button
          @click="onSave"
          :height="30"
          :width="80"
          style-type="main"
          :disabled="isSubmitting"
        >保存并关闭</znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";

const CONFIG = {
  configURL: "FinCapitalFlowDetails/GetConfig",
  // saveConfigURL: 'FinCapitalFlowDetails/SaveConfig',
  // resetConfigURL: 'FinCapitalFlowDetails/ResetConfig',
  searchURL: "FinCapitalFlowDetails/GetList",
  updateURL: "FinCapitalFlowDetails/Edit",
  deleteURL: "FinCapitalFlowDetails/Del",
  multiDeleteURL: "FinCapitalFlowDetails/MultiDel"
};

export default {
  name: "FinCapitalFlowDetails",
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {},
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    RPGUID: String,
    hasSeeDelete: {
      type: Boolean,
      default: true
    },
    loading: Boolean,
    isRefresh: Boolean, // 是否刷新
    height: String,
    visible: Boolean
  },
  data() {
    return {
      gridShow: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      lastDeletList: [],
      isSubmitting: false
    };
  },
  watch: {
    isRefresh: {
      handler(value) {
        this.onBindData();
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function() {
      // let config = await this.$post(this.$options.config.configURL)
      let config = await this.isSaveStorage(
        "finCapitalFlowDetails",
        this.$options.config.configURL
      );
      this.lastDeletList = [];
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      if (this.hasSeeDelete) {
        columns.push({
          name: "opelog",
          dataType: 5,
          binding: "opelog",
          width: 45,
          header: "删除",
          visible: true,
          allowSorting: false,
          isReadOnly: true,
          isFieldsSearch: false,
          isSystem: true,
          buttons: [
            {
              content: "删除",
              // 'className': '', // (d) => d.IsStkOuted === true ? 'success' : '',
              className: "znl-table-btn-success", // (d) => 'btnBackgroundColor',
              when: true,
              click: row => {
                this.onDelete(row);
              }
            }
          ]
        });
      }
      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.resourceList = config.ResourceList.map(item => item.Code);
      _.delay(() => {
        this.onBindData();
      }, 150);
    },
    onBindData(pageIndex = 1) {
      let semodel = { RPGUID: this.RPGUID };
      let data = _.extend(
        {
          FieldWhereString: JSON.stringify(semodel),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        { RPGUID: this.RPGUID }
      );
      this.lastDeletList = [];
      this.$post(CONFIG.searchURL, data, datas => {
        this.pageIndex = 1;
        this.itemSource = datas;
        this.totalCount = datas.length;
        this.gridShow = true;
      });
    },
    onCancel() {
      this.$emit("confirm", false);
    },
    async onSave() {
      if (this.lastDeletList.length <= 0) {
        return;
      }
      this.isSubmitting = true;
      let result = await this.$post(CONFIG.multiDeleteURL, this.lastDeletList);

      if (_.isNumber(result) && result > 0) {
        this.$message({ message: "保存成功", type: "success" });
        // this.visible = false
        this.$emit("confirm", false);
      } else {
        this.$message({ message: "保存失败", type: "error" });
      }
      this.isSubmitting = false;
    },
    onDelete(obj) {
      // console.log(obj)
      this.$nextTick(function() {
        let itemsources = this.itemSource;
        let newItemSource = [];
        this.lastDeletList.push(obj);
        _.each(itemsources, item => {
          if (item.RPLineID !== obj.RPLineID) {
            newItemSource.push(item);
          }
        });
        this.itemSource = newItemSource;
      });
    }
  }
};
</script>

<style lang="scss">
</style>

  <!-- 月结服务 -->
<template>
  <iframe :src="iframeSrc" frameborder="0" width="100%" height="100%"></iframe>
</template>
<script>
import { mixin as common } from "~assets/scripts/methods/common";
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { ERPCOOKIENAME } from "@scripts/config";
const CONFIG = {
  bomBuyUrl: "https://www.bombuy.com/advance-order/callback?token=",
};

export default {
  name: "LoanService",
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  config: CONFIG,
  // 组件
  components: {},
  watch: {},
  computed: {},
  data() {
    return {
      iframeSrc: "",
      token: "",
    };
  },
  // 父组件传过来的参数
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
  },
  // 方法
  methods: {
    async onInit() {
      var erpToken =
        this.getCookie(ERPCOOKIENAME, this.$store) || getCookie2("login_token");

      this.token = encodeURIComponent(erpToken.slice(6));
      console.log("init token:", this.token);

      this.iframeSrc = `${CONFIG.bomBuyUrl}${this.token}&orderNo=${this.orderNo}&from=${this.from}`;
      // this.iframeSrc = `http://localhost:1234?token=${this.token}&orderNo=${this.orderNo}&from=${this.from}`;

      console.log("init iframeSrc:", this.iframeSrc);
    },
    getCookie(name, store) {
      if (store) {
        return store.state[name];
      }
      return "";
    },
  },

  watch: {
    orderNo(n, o) {
      this.iframeSrc = `${CONFIG.bomBuyUrl}${this.token}&orderNo=${n}&from=${this.from}`;
      // this.iframeSrc = `http://localhost:1234?token=${this.token}&orderNo=${n}&from=${this.from}`;

      console.log("iframeSrc:", this.iframeSrc);
    },
  },
  async mounted() {
    await this.onInit();
  },
};
</script>

<template>
  <!-- 发货清单 -->
  <znl-gridmodule
    ref="flexGrid"
    height="323px"
    gridtype="action"
    layout-type="other"
    :has-znl-btns="true"
    :action-btns="actionBtns"
    element-loading-text="正在保存,请稍等..."
    :is-multi-rows-check-fixed="false"
    :columns="columns"
    :item-source="itemSource"
    :edit-row-index="editRowIndex"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :on-init="onInit"
    :users="$store.state.users"
    :loading="loading"
    :is-show-rightbtns="false"
    :show-add-row-right-menu="false"
    :show-delete-row-right-menu="false"
    @edit:cell-ended="onCellEditEnd"
    @page-loading="d=>{$emit('page-loading', d)}"
  >

    <stkout-selecct
      v-if="showSelect"
      :visible="showSelect"
      :customer-name='customerName'
      @close="val=>{showSelect = false}"
      @confirm="(rows)=> {showSelect = false, onStkoutConfirm(rows)}"
    ></stkout-selecct>
  </znl-gridmodule>
</template>
<script>
import StkoutSelecct from '@c_modules/Fin/SalesInvoice/StkoutSelect'

const CONFIG = {
  configURL: 'SalesInvoiceStkout/GetConfig'
}

export default {
  name: 'SalesInvoiceEditStkout',
  components: {
    StkoutSelecct
  },
  data () {
    return {
      title: '',
      columns: [],
      itemSource: [],
      columnColors: [],
      rowColors: [],
      editRowIndex: -1,
      loading: false,
      newRowIndex: -1,
      actionBtns: [],
      showSelect: false
    }
  },
  props: {
    customerName: String,
    taxRate: Number
  },
  methods: {
    async onInit () {
      let config = await this.isSaveStorage('SalesInvoiceStkout', CONFIG.configURL)
      // 获取主单参数配置
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      this.pageSize = config.PageSize

      let hideCols = ['Brand', 'Packaging', 'NoInvoiceAmount']
      _.each(columns, col => {
        if (hideCols.indexOf(col.binding) > -1) {
          col.visible = false
        }
      })

      this.initBtns()
      this.appendOperatCol(columns)
      this.columns = columns
      this.$nextTick(() => {
        this.dialogLoading = false
      })
    },

    initBtns () {
      this.actionBtns = [
        {
          name: '新增发货清单',
          // isShow: this.hasRes('Add'),
          iconName: 'icon-add_btn_ic',
          click: () => { this.onAdd() }
        }
        // {
        //   name: '删除',
        //   // isShow: this.hasRes('Delete'),
        //   iconName: 'icon-delete_btn_ic',
        //   click: () => { this.onDeleteRows() }
        // }
      ]
    },

    appendOperatCol (columns) {
      columns.push(
        {
          binding: 'operation',
          name: 'operation',
          header: '操作',
          visible: true,
          dataType: 1,
          width: 120,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: true,

          buttons: (row) => {
            let txt = '加到发票清单'
            // if (row.isAdded) {
            //   txt = '重新添加'
            // }
            return [
              {
                content: '移除',
                className: 'znl-table-btn-success',
                click: (row) => {
                  this.onRemoveRow(row)
                }
              },
              {
                content: txt,
                className: 'znl-table-btn-success',
                click: (row) => {
                  this.onAddToLineList(row)
                }
              }
            ]
          }
        },
      )
    },

    // 添加发货清单
    onAdd () {
      if (this.customerName && this.customerName !== '') {
        this.showSelect = true
      } else {
        this.$message({message: '请先选择购买方', type: 'error'})
      }
    },

    // 移除一行
    onRemoveRow (row) {
      _.each(this.itemSource, (item, index) => {
        if (_.isEqual(item, row)) {
          this.itemSource.splice(index, 1)
        }
      })
    },

    // 加到发票清单
    onAddToLineList (row) {
      row.isAdded = true
      this.$emit('add-invoice-line', row)
    },

    // 选中销售未开票
    onStkoutConfirm (rows) {
      let showCfm = false
      let vm = this
      if (this.taxRate) {
        _.each(rows, row => {
          if (row.TaxRate !== this.taxRate) {
            showCfm = true
            return true
          }
        })
      }

      function addToDS () {
        _.each(rows, row => {
          if (!_.some(vm.itemSource, item => item.StkOutLineGUID === row.StkOutLineGUID)) {
            vm.itemSource.push(row)
          }
        })
      }

      if (showCfm) {
       this.$confirm('您正在将不同税率的发货明细添加到发票中，是否继续?', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          addToDS()
        })
      } else {
        addToDS()
      }

    },

    onCellEditEnd (afterValue, beforeValue, colName, gridObj, rowIndex, colIndex) {
      let row = gridObj.row
      switch (colName) {
        case 'InvoiceQty':
          let qty = this.toFloat(afterValue, 0)
          if(qty > row.NoInvoiceQty) {
            qty = row.InvoiceQty = beforeValue
            this.$message({ message: '本次数量不能大于未开数量(' + row.NoInvoiceQty + ')', type: 'error' })
          }
          if (qty) {
            row.InvoiceAmount = this.toFixed(row.PriceInTax * qty, 2)
          } else {
            row.InvoiceAmount = ''
          }
        break
      }
    },

    getNumber (val, digit = 0) {
      if (isNaN(val)) {
        return null
      } else {
        return parseFloat(val.toFixed(digit))
      }
    },

    toFixed (val, digit = 0) {
      if (isNaN(val)) {
        return val
      } else {
        return parseFloat(parseFloat(val).toFixed(digit))
      }
    },

    onInvoiceListQtyChanged (afterValue, beforeValue, lineGuid) {
      if(this.itemSource === undefined || this.itemSource === null || this.itemSource.length === 0) {
        return;
      }

      _.forEach(this.itemSource, a => {
        if (a.StkOutLineGUID === lineGuid) {
          a.InvoiceQty = afterValue > a.InvoiceQty ? a.InvoiceQty : afterValue;

          if (a.InvoiceQty) {
            a.InvoiceAmount = this.toFixed(a.PriceInTax * a.InvoiceQty, 2)
          } else {
            a.InvoiceAmount = ''
          }
        }
      })
    }
  }
}
</script>


<template>
  <znl-layout-spa
    class="tab-fin-btnstyle-box grid-footer-right"
    :page-loading="pageLoading"
    height="100%"
    znl-loading-text="页面加载中,请稍后..."
  >
    <el-tabs
      value="FinManagement"
      class="nav-common-ui tab-btnstyle-box"
      v-model="activeName"
      v-show="!pageLoading"
      @tab-click="pageSearch"
    >
      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->

      <el-tab-pane
        label="资金流水"
        name="FinCapitalFlow"
        v-if="hasRight('FinCapitalFlow')"
      >
        <znl-fin-capital-flow
          v-if="finCapitalFlowInitData"
          :init-data="finCapitalFlowInitData"
          :refresh-bank="bankAccountChange"
          @page-loading="setClientSelectLoading"
        ></znl-fin-capital-flow>
      </el-tab-pane>

      <el-tab-pane
        label="银行账户"
        name="FinBankAccount"
        v-if="hasRight('FinBankAccount')"
      >
        <znl-fin-bank-account
          v-if="bankInitData"
          :init-data="bankInitData"
          @page-loading="setClientSelectLoading"
          @on-bank-changed="
            () => {
              bankAccountChange = !bankAccountChange;
            }
          "
        ></znl-fin-bank-account>
      </el-tab-pane>

      <el-tab-pane
        label="收支明细"
        name="FinInAndExpDetails"
        v-if="hasRight('FinInAndExpDetails')"
      >
        <znl-fin-in-and-exp-details
          v-if="detailsInitData"
          :init-data="detailsInitData"
          :refresh-bank="bankAccountChange"
          @page-loading="setClientSelectLoading"
        ></znl-fin-in-and-exp-details>
      </el-tab-pane>

      <el-tab-pane
        label="预付款"
        name="FinPayInAdvance"
        v-if="hasRight('FinPayInAdvance')"
      >
        <znl-fin-pay-in-advance
          v-if="payInitData"
          :init-data="payInitData"
          :refresh-bank="bankAccountChange"
          @page-loading="setClientSelectLoading"
        ></znl-fin-pay-in-advance>
      </el-tab-pane>

      <el-tab-pane
        label="预收款"
        name="FinRecInAdvance"
        v-if="hasRight('FinRecInAdvance')"
      >
        <znl-fin-rec-in-advance
          v-if="recInitData"
          :init-data="recInitData"
          @page-loading="setClientSelectLoading"
          :refresh-bank="bankAccountChange"
        ></znl-fin-rec-in-advance>
      </el-tab-pane>

      <!-- 销售未开票 hasRight('SalesInvoice')-->
      <el-tab-pane
        label="销售未开票"
        name="SalesWaitInvoice"
        v-if="hasRight('FinCapitalFlow')"
      >
        <sales-wait-invoice
          v-if="salesWaitInvoiceInitData"
          :init-data="salesWaitInvoiceInitData"
          @page-loading="
            (val) => {
              pageLoading = val;
            }
          "
        ></sales-wait-invoice>
      </el-tab-pane>

      <!-- 销售发票 hasRight('PurchaseInvoice')-->
      <el-tab-pane
        label="销售已开票"
        name="SalesInvoice"
        v-if="hasRight('FinCapitalFlow')"
      >
        <sales-invoice
          v-if="salesInvoiceInitData"
          :init-data="salesInvoiceInitData"
          @page-loading="setClientSelectLoading"
        ></sales-invoice>
      </el-tab-pane>

      <!-- 采购未收票 hasRight('PurchaseInvoice')-->
      <el-tab-pane
        label="采购未收票"
        name="PurchaseWaitInvoice"
        v-if="hasRight('FinCapitalFlow')"
      >
        <purchase-wait-invoice
          v-if="purchaseWaitInvoiceInitData"
          :init-data="purchaseWaitInvoiceInitData"
          @page-loading="
            (val) => {
              pageLoading = val;
            }
          "
        ></purchase-wait-invoice>
      </el-tab-pane>
      <!-- 采购发票 -->
      <!-- hasRight('PurchaseInvoice') -->
      <el-tab-pane
        label="采购已收票"
        name="PurchaseInvoice"
        v-if="hasRight('FinCapitalFlow')"
      >
        <purchase-invoice
          :init-data="purchaseInvoiceInitData"
          v-if="purchaseInvoiceInitData"
          :searchModel="purchaseInvoiceSearchModel"
          @page-loading="
            (val) => {
              pageLoading = val;
            }
          "
        ></purchase-invoice>
      </el-tab-pane>

      <!-- <el-tab-pane
        label="月结服务"
        name="LoanService"
        v-if="hasLoanService && hasRight('FinLoanService')"
      >
        <LoanService
          v-if="orderNo != ''"
          :orderNo="orderNo"
          :from="monthServiceFrom"
        ></LoanService>
      </el-tab-pane> -->

      <!-- <a class="old-edition" @click="oldEdition()">切换到旧版</a> -->
    </el-tabs>
  </znl-layout-spa>
</template>
<script>
import { switchToLegacyPage } from "~/lib/native";
import SalesInvoice from "@c_modules/Fin/SalesInvoice/Invoice";
import SalesWaitInvoice from "@c_modules/Fin/SalesInvoice/WaitInvoice";
import PurchaseInvoice from "@c_modules/Fin/PurchaseInvoice/Invoice";
import PurchaseWaitInvoice from "@c_modules/Fin/PurchaseInvoice/WaitInvoice";
import ZnlFinCapitalFlow from "@c_modules/Fin/FinCapitalFlow_v2";
// import ZnlFinCapitalFlow from "@c_modules/Fin/FinCapitalFlow_v1";
import ZnlFinBankAccount from "@c_modules/Fin/FinBankAccount_v1";
import ZnlFinPayInAdvance from "@c_modules/Fin/FinPayInAdvance";
import ZnlFinRecInAdvance from "@c_modules/Fin/FinRecInAdvance";
import ZnlFinInAndExpDetails from "@c_modules/Fin/FinInAndExpDetails_v1";
import LoanService from "@c_modules/Fin/LoanService";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

export default {
  name: "FinManagement",
  mixins: [getCommonDataMixin],
  components: {
    switchToLegacyPage,
    // ZnlFinSoInvoiceManage,
    ZnlFinCapitalFlow,
    ZnlFinBankAccount,
    ZnlFinPayInAdvance,
    ZnlFinRecInAdvance,
    ZnlFinInAndExpDetails,
    switchToLegacyPage,
    SalesInvoice,
    SalesWaitInvoice,
    PurchaseWaitInvoice,
    PurchaseInvoice,
    LoanService,
  },
  data() {
    return {
      gridShow: true,
      orderNo: "",
      monthServiceFrom: "",
      bankSearchModel: {
        data: {},
        extra: {},
      },
      paySearchModel: {
        data: {},
        extra: {},
      },
      recSearchModel: {
        data: {},
        extra: {},
      },
      detailsSearchModel: {
        data: {},
        extra: {},
      },
      salesInvoiceSearchModel: {
        data: {},
        extra: {},
      },
      purchaseInvoiceSearchModel: {
        data: {},
        extra: {},
      },
      pageData: [],
      pageLoading: true,
      finCapitalFlowInitData: false,
      bankInitData: false,
      payInitData: false,
      recInitData: false,
      detailsInitData: false,
      salesInvoiceInitData: false,
      salesWaitInvoiceInitData: false,
      purchaseInvoiceInitData: false,
      purchaseWaitInvoiceInitData: false,
      getdataLoading: false,
      activeName: "FinCapitalFlow",
      bankAccountChange: false,
      hasLoanService: false,
    };
  },
  props: {},
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    },
    $route(to, from) {
      if (to.name !== "Fin/FinManage") return;
      if (to.query.tag) {
        this.activeName = to.query.tag;
      }
      if (to.query.orderNo) {
        this.orderNo = to.query.orderNo;
      }
      if (to.query.from) {
        this.monthServiceFrom = to.query.from;
      }
    },
  },
  async mounted() {
    this.hasLoanService = await this.hasLoanServiceAsync;

    if (this.$route && this.$route.query && this.$route.query.tag) {
      this.activeName = this.$route.query.tag;
      this.orderNo = this.$route.query.orderNo;
    }
  },
  methods: {
    tagNamechang() {
      this.activeName = "LoanService";
    },
    onInit() {
      let name = "";

      if (this.hasRight("FinCapitalFlow")) {
        name = "FinCapitalFlow";
        this.finCapitalFlowInitData = true;
      } else if (this.hasRight("FinBankAccount")) {
        name = "FinBankAccount";
        this.bankInitData = true;
      } else if (this.hasRight("FinPayInAdvance")) {
        name = "FinPayInAdvance";
        this.payInitData = true;
      } else if (this.hasRight("FinRecInAdvance")) {
        name = "FinRecInAdvance";
        this.recInitData = true;
      } else if (this.hasRight("FinInAndExpDetails")) {
        name = "FinInAndExpDetails";
        this.detailsInitData = true;
      } else if (this.hasRight("FinCapitalFlow")) {
        // 销售发票
        name = "SalesInvoice";
        this.salesInvoiceInitData = true;
      } else if (this.hasRight("FinCapitalFlow")) {
        // 采购发票
        name = "PurchaseInvoice";
        this.purchaseInvoiceInitData = true;
      } else if (this.hasRight("FinCapitalFlow")) {
        // 销售未开票
        name = "SalesWaitInvoice";
        this.salesWaitinvoiceInitData = true;
      } else if (this.hasRight("FinCapitalFlow")) {
        // 采购发票
        name = "PurchaseWaitInvoice";
        this.purchaseWaitinvoiceInitData = true;
      } else if (this.hasRight("FinLoanService")) {
        // 月结服务
        name = "LoanService";
      }

      this.activeName = name;

      let hasRouteTag = false,
        routeTag = null;
      if (this.$route && this.$route.query.tag) {
        hasRouteTag = true;
        routeTag = this.$route.query.tag;
      }
      if (hasRouteTag && routeTag) {
        this.setClientSelectLoading(true);
        this.activeName = routeTag;
      }

      this.purchaseWaitinvoiceInitData = false;
    },
    hasRight(code) {
      return _.some(this.pageData, (page) => page === code);
    },
    setClientSelectLoading(val) {
      this.pageLoading = val;
    },
    pageSearch() {
      if (this.activeName === "FinCapitalFlow") {
        this.finCapitalFlowInitData = true;
      } else if (this.activeName === "FinBankAccount") {
        this.bankInitData = true;
      } else if (this.activeName === "FinPayInAdvance") {
        this.payInitData = true;
      } else if (this.activeName === "FinRecInAdvance") {
        this.recInitData = true;
      } else if (this.activeName === "FinInAndExpDetails") {
        this.detailsInitData = true;
      } else if (this.activeName === "SalesInvoice") {
        this.salesInvoiceInitData = true;
      } else if (this.activeName === "SalesWaitInvoice") {
        this.salesWaitInvoiceInitData = true;
      } else if (this.activeName === "PurchaseInvoice") {
        this.purchaseInvoiceInitData = true;
      } else if (this.activeName === "PurchaseWaitInvoice") {
        this.purchaseWaitInvoiceInitData = true;
      }
    },
    async oldEdition() {
      await switchToLegacyPage("Finance");
    },
    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VFinManage" });
    },
  },

  created() {
    this.getPageData()
      .then((data) => {
        if (data && data.length > 0) {
          this.pageData = data.map((item) => item.Code);
        }
        this.onInit();
      })
      .catch((e) => {});
  },
};
</script>
<style lang="scss">
</style>

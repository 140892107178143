<template>
<!-- 销售未开票列表 -->
  <znl-dialog
    :title="title"
    :visible="visible"
    height="650px"
    width="999px"
    :close-on-click-modal="false"
    :is-footer-show="false"
    v-loading="loading"
    :append-to-body="true"
    element-loading-text="数据加载中,请稍后..."
    @close="val=>{$emit('close', val)}"
  >
    <znl-table ref="table"
      height="100%"
      loading-type="animation"
      layout-type="other"
      :columns="columns"
      :item-source="itemSource"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-check="true"
      v-loading="gridShow"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      :loading="loading"
      @select-change="rows => {selectedRows = rows}"
      @page-loading="d=>{$emit('page-loading', d)}">

      <div slot="header" class="v-table-toolbar">

        <znl-input from-type="input"
          :disable="false"
          placeholder="请输入型号"
          title="型号："
          size="mini"
          layout="left"
          title-width="30px"
          width="150px"
          inp-perc="100%"
          class="box_input_checkbox"
          :border="true"
          :clearable="true"
          v-model="filterFields.Model"
          @keyup.enter.native="onSearch()"
          type="text"></znl-input>

        <znl-input
          form-type="input"
          placeholder="请输入发货单号"
          title="发货单号："
          title-width="65px"
          width='180px'
          size="mini"
          layout="left"
          type="text"
          :clearable="true"
          :border="true"
          v-model="filterFields.BillNo"
          @keyup.enter.native="onSearch()">
        </znl-input>

        <znl-button class="box-left-btn search-btn" :height='22' style-type="main" @click="onSearch()">
          <i class="iconfont icon-search_ic"></i><span> 搜索</span>
        </znl-button>

        <znl-button style-type='main' @click="onConfirm" :height='22'>
          <span><i class="iconfont icon-add_btn_ic"></i>确认选择</span>
        </znl-button>

        <!-- <a style="text-decoration:underline; margin-left:3px;margin-right:3px;" @click="onCheckInfo()" title="已选中条数">已选择 ({{selectedRows.length}}) 条</a>
        <a @click="onClearSelectCount()" style="text-decoration:underline; margin-left:5px;" title="清除选中条数">清除</a> -->

      </div>
    </znl-table>
    <stk-check-info
      ref="stkCheckInfo"
      v-if="showStkCheckInfo"
      :visible='showStkCheckInfo'
      @checkInfo="(val)=>{this.onSeletedInfo(val)}"
      @close="()=>{showStkCheckInfo=false}">
    </stk-check-info>
  </znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import SalesInvoiceEdit from '@c_modules/Fin/SalesInvoice/InvoiceEdit'
import StkCheckInfo from '@c_modules/Stk/StkCheckInfo'

const CONFIG = {
  PK: 'StkOutLineGuid',
  configURL: 'SalesInvoiceStkout/GetConfig',
  searchURL: 'SalesInvoiceStkout/Search'
}
export default {
  name: 'SalesInvoice',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  // 组件
  components: {
    SalesInvoiceEdit,
    StkCheckInfo
  },
  watch: {
    selectOptionsSalesCompanyInfo (val) {
      _.each(this.columns, column => {
        if (column.binding === 'SalesCompanyID') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  data () {
    return {
      isShowFiedsSearch: false,
      isReturninvoice: false,
      columns: [],
      itemSource: [],
      filterFields: {
        Model: '',
        BillNo: ''
      },
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，,
      gridShow: false,
      loading: false,
      selectedRows: [],
      showStkCheckInfo: false
    }
  },
  computed: {
    title () {
      return '选择未开票清单 - ' + this.customerName
    }
  },
  // 父组件传过来的参数
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    visible: Boolean,
    customerName: String
  },
  // 方法
  methods: {
    onInit: async function (config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage('SalesInvoiceStkout', CONFIG.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = config.ColumnConfigs

      let hideCols = ['InvoiceAmount', 'TicketedAmount']
      _.each(columns, col => {
        if (hideCols.indexOf(col.BindField) > -1) {
          col.IsShow = false
        }
      })

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$nextTick(() => {
        this.$emit('grid-show', true)
        this.$emit('page-loading', false)
      })
    },

    onSearch (params = null) {
      if (params === null) {
        this.filterFields = _.extend({}, this.filterFields, params)
      }
      return this.onBindData(1)
    },
    // 刷新
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },

    onCheckInfo () {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: '至少选择一条数据', type: 'warning' })
      } else {
        _.each(this.selectedRows, item => {
          item.CheckGUID = item.StkOutLineGUID
        })
        let order = { checkInfo: this.selectedRows }
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.showStkCheckInfo = true
        })
      }
    },

    // 访问接口,绑定数据
    onBindData (pageIndex = 1) {
      this.loading = true
      this.pageIndex = pageIndex
      let param = _.extend({},
        this.filterFields,
        {
          CustomerName: this.customerName,
          IsFullmatch: true,
          PageIndex: this.pageIndex,
          PageSize: this.pageSize
        }
      )
      this.statementSelectItem = null
      this.$post(CONFIG.searchURL, param, data => {
        this.loading = false
        this.pageIndex = data.PageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('grid-show', true)
        this.$emit('page-loading', false)
      })
    },

    // 确认选择
    onConfirm () {
      let rows = this.selectedRows
      if (rows && rows.length > 0) {
        this.$emit('confirm', rows)
      } else {
        this.$message({message: '请至少选择一条数据，然后再进行操作', type: 'warning'})
      }
    },

    onClearSelectCount () {
      this.selectedRows = []
    }
  },
  async mounted () {
    await this.onInit()
  }
}
</script>

<style lang="scss">
.p-sales-invoice {
  .underline{
    text-decoration: underline;
    font-size: 12px;
  }
}
</style>


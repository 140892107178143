<template>
  <div>
    <!--编辑资金流水-->
    <znl-dialog
      :title="title"
      :visible="visible"
      height="370px"
      width="600px"
      class="dialog-fincapital-add-box"
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      :element-loading-text="LoadingText"
      @close="()=>{$emit('close')}"
    >
      <div class="row-box">
        <el-row class="form_Row_W100">
          <el-col :span="12">
            <znl-input
              form-type="select"
              :disabled="isEdit"
              placeholder="--请选择--"
              size="mini"
              layout="left"
              :is-must-fill="true"
              :border="true"
              title="收支类型："
              width="250px"
              :clearable="false"
              title-width="90px"
              :select-options="paymentTypeOptions"
              v-model="finCapital.BillType"
              type="text"
            ></znl-input>
          </el-col>
          <el-col :span="12">
            <znl-input
              form-type="datepicker"
              :disabled="isEdit"
              placeholder="记账日期"
              size="mini"
              :clearable="true"
              layout="left"
              :is-must-fill="true"
              :border="true"
              title="记账日期："
              width="100%"
              title-width="120px"
              v-model="finCapital.BusinessDate"
            ></znl-input>
          </el-col>
        </el-row>
        <el-row class="form_Row_W100">
          <el-col :span="24">
            <znl-input
              form-type="select"
              :disabled="isEdit"
              placeholder="请选择"
              size="mini"
              layout="left"
              :is-must-fill="true"
              :border="true"
              title="账户名称："
              width="100%"
              :clearable="false"
              title-width="90px"
              @change="onChangeBankAcct"
              :select-options="selectOptionsBankAccountAndName"
              v-model="finCapital.BankAccountGUID"
              type="text"
            ></znl-input>
          </el-col>
        </el-row>
        <el-row class="form_Row_W100">
          <el-col :span="12">
            <znl-input
              form-type="input"
              :disabled="false"
              placeholder="金额"
              size="mini"
              layout="left"
              :border="true"
              :is-must-fill="true"
              title="金额："
              width="250px"
              title-width="90px"
              v-model="finCapital.Balance"
              :clearable="true"
              type="text"
            ></znl-input>
          </el-col>
          <el-col :span="12">
            <znl-input
              form-type="select"
              placeholder="--请选择--"
              size="mini"
              layout="left"
              title="经手人："
              width="100%"
              :clearable="true"
              :border="true"
              title-width="120px"
              @change="onChangeUser"
              :select-options="selectOptionsAllAccountList"
              v-model="finCapital.HandlerGUID"
              type="text"
            >
              <!-- :select-options="selectOptionsAllAccount" -->
            </znl-input>
          </el-col>
        </el-row>
        <el-row class="form_Row_W100">
          <el-col :span="24">
            <znl-input
              form-type="input"
              :disabled="false"
              placeholder="公司名称"
              size="mini"
              layout="left"
              :is-must-fill="false"
              :border="true"
              title="关联公司信息："
              width="100%"
              title-width="90px"
              :on-icon-click="SearchCompany"
              v-model="finCapital.CompanyName"
              type="text"
            >
              <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
            </znl-input>
            <!-- <company-optional
                placeholder="公司名称"
                size="mini"
                layout="left"
                :clearable="false"
                :border="true"
                title="关联公司信息："
                title-width="90px"
                :is-must-fill="true"
                dialog-title="选择关联公司"
                company-type='1,2,3'
                open-from='capital'
                :value="finCapital.CompanyName"
                @confirm="onCompanyConfirm"
                width="100%"
            ></company-optional>-->
          </el-col>
        </el-row>
        <el-row class="form_Row_W100">
          <el-col>
            <znl-input
              form-type="input"
              :disabled="false"
              placeholder="摘要"
              size="mini"
              layout="left"
              :is-must-fill="false"
              :border="true"
              title="摘要："
              width="100%"
              :rows="5"
              :clearable="true"
              title-width="90px"
              v-model="finCapital.Abstract"
              type="textarea"
            ></znl-input>
          </el-col>
        </el-row>
      </div>

      <el-row align="middle" type="flex" justify="end" slot="footer" class="dialog-footer">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-checkbox id="continueSave" v-model="isSaveAndContinue" style="margin-right:5px"></el-checkbox>
          <label for="continueSave" style="margin-right:3px;">保存后继续操作</label>
          <znl-button :height="30" :width="60" @click="onClear">清空</znl-button>
          <znl-button
            @click="onSave"
            :height="30"
            :width="60"
            style-type="main"
            :disabled="dialogLoading"
          >保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      :companyType="InTypes"
      title="选择公司"
      @close="()=>{dialogCompanySelectVisible=false}"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
      @confirm="companyChecked"
    ></d-company-contact-select>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import CompanyOptional from "@c_modules/Control/CompanyInputOptional";

const CONFIG = {
  capitalFlowAddURL: "FinCapitalFlow/Add",
  capitalFlowEditURL: "FinCapitalFlow/Edit",
  capitalFlowGetURL: "FinCapitalFlow/Get"
};

export default {
  name: "FinCapitalAdd",
  mixins: [znlMethodsMixin, getCommonDataMixin],
  components: {
    dCompanyContactSelect,
    CompanyOptional
  },
  data() {
    return {
      isSaveAndContinue: false,
      isEdit: false,
      dialogLoading: false,
      LoadingText: "",
      finCapital: {
        RPGUID: "",
        BillType: 0,
        CCompanyID: "",
        CompanyName: "",
        Expenditure: "", // 金额，支出
        InCome: "",
        BankAccountNo: "", // 账户号码
        BankAccountName: "", // 账户名称
        BankAccountGUID: "",
        BusinessDate: null, // 付款时间
        CreatedByName: "", // 录入人
        CreatedGUID: "",
        Balance: 0,
        Handler: "",
        HandlerGUID: "",
        Abstract: "" // 备注
      },
      paymentTypeOptions: [
        { key: 0, value: "--请选择--" },
        { key: 3, value: "其它收入" },
        { key: 4, value: "其它支出" },
        { key: 5, value: "期初余额" }
      ],
      selectOptionsAllAccountList: [],
      dialogCompanySelectVisible: false,
      InTypes: "1,3" // 1:客户，2：供应商，3:两者都是的公司
    };
  },
  props: {
    RPGUID: String,
    isRefresh: Boolean, // 是否刷新
    title: String,
    visible: Boolean
  },
  watch: {
    RPGUID: {
      handler(value) {
        this.isEdit = !_.isEmpty(value);
        this.LoadPage(value);
      },
      immediate: true
    },
    isRefresh: {
      handler(value) {
        this.onResetPage(true);
      },
      immediate: true
    }
  },
  methods: {
    onClear() {
      this.onResetPage();
    },
    LoadPage(guid) {
      this.onResetPage();
      // if (guid !== '') {
      this.$post(CONFIG.capitalFlowGetURL, { RPGUID: guid }, datas => {
        if (!this.isEdit) {
          // 新增时经手人默认为当前登录账户
          this.finCapital.Handler = datas.Handler;
          this.finCapital.HandlerGUID = datas.HandlerGUID;
        } else {
          datas.Handler = datas.Handler || "";
          datas.HandlerGUID = datas.HandlerGUID || "";
          // Balance
          switch (datas.BillType) {
            case 1:
            case 3:
            case 5:
            case 6:
              datas.Balance = datas.InCome;
              break;
            case 2:
            case 4:
            case 7:
              datas.Balance = datas.Expenditure;
              break;
          }
          this.finCapital = datas;
        }
      });
      // }
    },
    onResetPage(setDDLToDefault) {
      // 重置页面
      // console.log('clear', this.isEdit)
      if (!this.isEdit) {
        this.finCapital.RPGUID = "";
        this.finCapital.CCompanyID = "";
        this.finCapital.CompanyName = "";
        this.finCapital.CreatedByName = ""; // 录入人
        this.finCapital.CreatedGUID = "";

        if (setDDLToDefault === true) {
          this.finCapital.BusinessDate = this.getNowFormatDate(); // 付款时间
          this.finCapital.BillType = 0;
          this.finCapital.BankAccountNo = ""; // 账户号码
          this.finCapital.BankAccountName = "--请选择--"; // 账户名称
          this.finCapital.BankAccountGUID = "0";
        }
      }

      this.finCapital.Handler = ""; // 经手人
      this.finCapital.HandlerGUID = ""; // 经手人
      this.finCapital.Expenditure = ""; // 金额，支出
      this.finCapital.InCome = "";
      this.finCapital.Balance = "";
      this.finCapital.Abstract = ""; // 备注
    },

    onChangeBankAcct(val) {
      if (val) {
        this.finCapital.BankAccountName = val.value;
        this.finCapital.BankAccountNo = val.key;
      }
    },
    onChangeUser(val) {
      if (val) {
        this.finCapital.handler = val.value;
      }
    },

    // 选中公司信息后触发
    onCompanyConfirm(item) {
      this.finCapital.CCompanyID = item.CCompanyID;
    },

    onSave() {
      // 保存
      if (this.CheckData()) {
        // Balance
        switch (this.finCapital.BillType) {
          case 1:
          case 3:
          case 5:
          case 6:
            this.finCapital.InCome = this.finCapital.Balance;
            break;
          case 2:
          case 4:
          case 7:
            this.finCapital.Expenditure = this.finCapital.Balance;
            break;
        }
        this.dialogLoading = true;
        this.LoadingText = "正在保存，请稍等...";
        this.$post(
          this.isEdit ? CONFIG.capitalFlowEditURL : CONFIG.capitalFlowAddURL,
          this.finCapital,
          datas => {
            this.dialogLoading = false;
            if (datas.RPGUID !== "") {
              this.$message({ message: "保存成功", type: "success" });
              if (!this.isEdit) {
                this.onResetPage();
              }
              if (this.isSaveAndContinue) {
                this.isEdit = false;
                this.onResetPage();
              } else {
                this.$emit("confirm", true);
              }
            }
          }
        ).finally(() => {
          this.dialogLoading = false;
        });
      }
    },
    CheckData() {
      // 检查数据的正确性
      let ret = true;
      // if (this.finCapital.CCompanyID === '' || this.finCapital.CompanyName === '') {
      //   ret = false
      //   this.$message({ message: '请选择关联公司名称', type: 'error' })
      // } else
      if (
        this.finCapital.BillType === "" ||
        this.finCapital.BillType === "0" ||
        this.finCapital.BillType === 0
      ) {
        ret = false;
        this.$message({ message: "请选择收支类型", type: "error" });
      } else if (
        this.finCapital.BankAccountGUID === "" ||
        this.finCapital.BankAccountGUID === null ||
        this.finCapital.BankAccountGUID === undefined ||
        this.finCapital.BankAccountGUID === ""
      ) {
        ret = false;
        this.$message({ message: "收支类型不能为空", type: "error" });
      } else if (
        this.finCapital.BusinessDate === "" ||
        this.finCapital.BusinessDate === null ||
        this.finCapital.BusinessDate === undefined
      ) {
        ret = false;
        this.$message({ message: "记账日期不能为空", type: "error" });
      } else if (
        this.finCapital.BankAccountGUID === "" ||
        this.finCapital.BankAccountGUID === "0" ||
        this.finCapital.BankAccountGUID === 0
      ) {
        ret = false;
        this.$message({ message: "请选择账户名称", type: "error" });
      } else if (this.finCapital.Balance === "") {
        ret = false;
        this.$message({ message: "请输入金额", type: "error" });
      } else if (!isFinite(this.finCapital.Balance)) {
        ret = false;
        this.$message({ message: "金额必须为数字或者小数", type: "error" });
      } else if (
        this.hasValue(this.finCapital.Abstract) &&
        this.finCapital.Abstract.length > 4000
      ) {
        ret = false;
        this.$message({
          message: "摘要过长，请不要超过4000个字符",
          type: "error"
        });
      }
      return ret;
    },
    SearchCompany() {
      this.InTypes = "1,2,3"; // 客户和供应商客户
      this.dialogCompanySelectVisible = true;
    },
    companyChecked(item) {
      if (this.hasValue(item)) {
        if (this.hasValue(item.CCompanyID)) {
          this.finCapital.CCompanyID = item.CCompanyID;
        }
        if (this.hasValue(item.CompanyName)) {
          this.finCapital.CompanyName = item.CompanyName;
        }
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    // 获取当前时间，格式YYYY-MM-DD
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    }
  },
  async created() {
    this.selectOptionsAllAccountList = await this.selectOptionsAllAccount;
  },
  async mounted() {}
};
</script>

<style lang="scss">
.dialog-fincapital-add-box {
  .form_Row_W100 {
    padding: 5px 0;
  }
  .znl-button {
    margin-left: 5px;
  }
}
</style>

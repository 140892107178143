<template>
  <!-- 预收款按客户汇总 -->
  <div class="module-wrapper m-fin-rec-pay-in-advance-total">
    <znl-table
      ref="table"
      gridtype="base"
      :showIndex="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      box-class="m-fin-rec-pay-in-advance-total"
      v-loading="loading"
      :totalField="['InCome']"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FinRecInAdvanceTotal')"
      @totalAmount-click="onShowDetail"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            :border="true"
            v-model="filterFields.CompanyName"
            placeholder="请输入公司名称"
            title="公司名称："
            title-width="70px"
            width="260px"
            :clearable="true"
          ></znl-input>
          <znl-button
            type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn ml5"
          >
            <znl-button-menu @click="SearchGridList">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
          </znl-button>
          <znl-button
            style-type="mac"
            :height="22"
            @click="toDetail"
            tip="预收明细"
          >
            <span>预收明细</span>
          </znl-button>
        </el-row>
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24" class="money-summary">
            <span>
              总金额：
              <span class="number">{{ summary.InCome }}</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

import {
  exportData,
  erpTableSetHandler,
  dateTimeFormat
} from "@scripts/methods/common";

const CONFIG = {
  PK: "RPGUID",
  configURL: "FinRecInAdvanceTotal/GetConfig",
  saveConfigURL: "FinRecInAdvanceTotal/SaveConfig",
  searchURL: "FinRecInAdvanceTotal/Search",
  getAdvanceSummaryURL: "FinRecInAdvanceTotal/GetAdvanceSummary"
};

export default {
  name: "FinRecInAdvanceTotal",
  mixins: [exportData, getCommonDataMixin, erpTableSetHandler, dateTimeFormat],
  config: CONFIG,
  initData: {
    type: Boolean,
    default: true
  },
  data() {
    return {
      RPGUID: "",
      columns: [],
      defaultConfig: {},
      itemSource: [],
      searchFields: {},
      filterFields: {
        CompanyName: "",
      },
      pageSize: 20000,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      onrefreshAdd: true,
      searchCondition: {},
      loading: false,
      summary: {
        BeginBalance: 0,
        EndBalance: 0,
        InCome: 0,
        Outgoings: 0
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("finReclnAdvanceTotal", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }

      let columns = config.ColumnConfigs;
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'InCome')], {
        event: 'totalAmount-click',
        className: 'v-cell-link'
      })
      this.columns = columns;
      this.defaultConfig = config;
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      //let vm = this
      //vm.getTotal()
      this.$emit("page-loading", false);
    },

    async onBindData(pageIndex = 1) {
      this.loading = true;
      let filterFields = {};
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[
            key.substring(0, 1).toUpperCase() + key.substring(1)
          ] = value;
        });
      }
      this.searchCondition = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          searchType: 2,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        filterFields
      );
      let response = await this.$post(CONFIG.searchURL, this.searchCondition);
      console.log(response)
      this.itemSource = response.ResultList;
      this.totalCount = response.TotalCount;
      setTimeout(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      }, 500);
      if (pageIndex == 1) {
        this.getTotal();
      }
    },
    SearchGridList() {
      this.onBindData(1);
    },
    search() {
      if(this.itemSource && this.itemSource.length > 0) {
        return
      }
      this.SearchGridList()
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    toDetail() {
      this.$emit("to-detail","");
    },
    onShowDetail(e) {
      if (e.field !== 'InCome') {
        return
      }
      if (this.hasValue(e.row.CompanyName)) {
        let companyName = e.row.CompanyName
        this.$emit("to-detail",companyName);
      }
    },
    // 获取合计数据
    getTotal() {
      if (this.pageIndex === 1) {
        this.$post(CONFIG.getAdvanceSummaryURL, this.searchCondition, data => {
          this.summary = data;
        });
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FinRecInAdvanceTotal", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("FinRecInAdvanceTotal", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("FinRecInAdvanceTotal", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FinRecInAdvanceTotal");
    }
  }
};
</script>

<style lang="scss">
.m-fin-rec-pay-in-advance-total {
  height: 100%;
  .search-box {
    .znl-input-title {
      padding-left: 5px;
      text-align: right;
    }
    .to {
      padding: 0 3px;
    }
  }
  .ml5 {
    margin-left: 5px;
  }
  .money-summary {
    span {
      margin-right: 12px;
      .number {
        color: rgb(255, 0, 0);
      }
    }
  }
}
</style>

<template>
  <!-- 采购发票列表 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      tooltip-content
      :columns="columns"
      :item-source="itemSource"
      :is-fields-search="isShowFiedsSearch"
      class="p-sales-invoice"
      :search-fields="onSearch"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      :totalField="['TaxAmount','InvoiceAmount']"
      :table-row-class-name="tabRowClassName"
      @page-loading="d=>{$emit('page-loading', d)}"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="开票时间起"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          title-width="55px"
          :border="true"
          :clearable="true"
          v-model="filterFields.InvoiceDateStart"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="开票时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.InvoiceDateEnd"
        ></znl-input>
        <!-- <el-button type="text" class="underline" @click="setCurrentMonth">本月</el-button> -->

        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="供应商名称"
            size="mini"
            layout="left"
            width="220px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.SellerName"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text"
          ></znl-input>
          <el-checkbox class="accurate-checbox" size="small" v-model="filterFields.IsFullMatch">精确</el-checkbox>
        </div>

        <znl-input
          form-type="input"
          placeholder="发票编号"
          width="120px"
          size="mini"
          layout="left"
          type="text"
          :clearable="true"
          :border="true"
          v-model="filterFields.InvoiceNumber"
          @keyup.enter.native="$refs.flexGrid.search()"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="规格型号"
          width="120px"
          size="mini"
          layout="left"
          type="text"
          :clearable="true"
          :border="true"
          v-model="filterFields.Model"
          @keyup.enter.native="$refs.flexGrid.search()"
        ></znl-input>
        <!-- <el-checkbox size="small" v-model="filterFields.IsWriteOff" style="margin:0 9px;">未核销</el-checkbox> -->

        <znl-button
          type="multiple"
          :height="22"
          :width="70"
          class="box-left-btn search-btn"
          style-type="mac"
        >
          <znl-button-menu @click="onSearch()">
            <span>
              <i class="iconfont icon-search_ic"></i>搜索
            </span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <!-- <znl-button style-type="mac" @click="onAdd" :height="22">
          <span>
            <i class="iconfont icon-add_btn_ic"></i>收票登记
          </span>
        </znl-button> -->
      </div>
    </znl-table>
    <!-- 采购发票新增_编辑 -->
    <invoice-edit
      :is-red-rush-invoice="isRedRushInvoice"
      v-if="invoiceVisible"
      :visible="invoiceVisible"
      :title="invoiceTitle"
      :PIGUID="PIGUID"
      @close="invoiceVisible=false"
      @save-success="onRefresh"
    ></invoice-edit>
  </div>
</template>
<script>
import { mixin as common } from "~assets/scripts/methods/common";
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import InvoiceEdit from "@c_modules/Fin/PurchaseInvoice/InvoiceEdit";
const CONFIG = {
  PK: "PIGUID",
  configURL: "PurchaseInvoice/GetConfig",
  saveConfigURL: "PurchaseInvoice/SaveConfig",
  resetConfigURL: "PurchaseInvoice/ResetConfig",
  onDeleteURL: "PurchaseInvoice/Delete",
  searchURL: "PurchaseInvoice/Search"
};
export default {
  name: "PurchaseInvoice",
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  config: CONFIG,
  // 组件
  components: {
    InvoiceEdit
  },
  watch: {},
  data() {
    return {
      isShowFiedsSearch: false,
      isRedRushInvoice: false,
      columns: [],
      itemSource: [],
      filterFields: {
        InvoiceNumber: "",
        CustomerName: "",
        MatchOperation: "",
        Model: "",
        IsFullMatch: false,
        InvoiceDateStart: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        InvoiceDateEnd: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        IsWriteOff: false // 未核销?
      },
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，,
      gridShow: false,
      parameterConfig: [],
      loading: false,
      // invoiceVisible: false,
      // invoiceTitle: '',
      PIGUID: "",
      invoiceVisible: false,
      invoiceTitle: "新增采购发票"
    };
  },
  // 父组件传过来的参数
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    }
  },
  // 方法
  methods: {
    onInit: async function(config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage("PurchaseInvoice", CONFIG.configURL);
      }
      this.resourceList = config.ResourceList.map(item => item.Code);
      let columns = config.ColumnConfigs;
      this.parameterConfig = config.ParamJosn;

      this.defaultConfig = config;
      this.columns = this.settingColumns(columns);
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$nextTick(() => {
        this.$emit("grid-show", true);
        this.$emit("page-loading", false);
      });
    },

    tabRowClassName(rowObj) {
      if (rowObj.row.BillsType === 2) {
        return "color-red";
      } else {
        return "";
      }
    },

    // 获取按钮权限
    hasRes(code) {
      if (code.length === 0) {
        return false;
      }
      return _.contains(this.resourceList, code);
    },

    settingColumns(columns) {
      let mouseBtn = [
        {
          title: "编辑",
          name: "edit",
          click: row => {
            this.onEdit(row);
          }
        },
        {
          title: "删除",
          name: "delete",
          click: row => {
            this.onDelete(row);
          }
        }
        // {
        //   title: '核销',
        //   name: 'writeoff',
        //   click: row => {
        //     // this.onEdit(row)
        //   }
        // }
      ];

      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true
        }
      ];

      _.extend(
        _.find(columns, col => col.BindField === "WriteOffStatus"),
        {
          type: "select",
          options: [
            { key: "未核销", value: 1 },
            { key: "已核销", value: 2 }
          ]
        }
      );
      _.extend(
        _.find(columns, col => col.BindField === "BillsType"),
        {
          type: "select",
          options: [
            { key: "正常", value: 1 },
            { key: "红冲", value: 2 }
          ]
        }
      );

      _.extend(
        _.find(columns, col => col.BindField === "InvoiceType"),
        {
          tyepe: "select",
          dataMap: [
            { key: "增值发票", value: 1 },
            { key: "普通发票", value: 2 }
          ]
        }
      );

      return tempConfig.concat(columns);
    },
    // 1.查看详情 打开采购发票编辑详情 2. 采购退货单的发票判定为冲红发票入口
    onEdit(row) {
      this.isRedRushInvoice = false; // 不是冲红发票
      this.$nextTick(function() {
        this.invoiceTitle = "编辑采购发票";
        this.openFrom = "edit";
        this.PIGUID = row.PIGUID;
        this.invoiceVisible = true;
      });
    },

    async onDelete(row) {
      let ok = await this.$confirm("删除确认, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });

      if (ok) {
        this.$post(
          CONFIG.onDeleteURL,
          { PIGUID: row.PIGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },

    onSearch(params = null) {
      if (params) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    setCurrentMonth() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      this.filterFields.InvoiceDateStart = new Date(y, m, 1);
      this.filterFields.InvoiceDateEnd = new Date(y, m + 1, 0);
    },
    // 刷新
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    // 高级搜索 搜索栏的显示和隐藏
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },

    // 访问接口,绑定数据
    onBindData(pageIndex = 1) {
      this.loading = true;
      this.pageIndex = pageIndex;
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          InvoiceNumber: this.filterFields.InvoiceNumber,
          CustomerName: this.filterFields.CustomerName,
          IsFullMatch: this.filterFields.IsFullMatch,
          InvoiceDateStart: this.filterFields.InvoiceDateStart,
          InvoiceDateEnd: this.filterFields.InvoiceDateEnd,
          Model: this.filterFields.Model
        }
      );
      this.$post(CONFIG.searchURL, binddata, data => {
        this.pageIndex = data.PageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("grid-show", true);
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },

    // 添加
    onAdd() {
      this.isRedRushInvoice = false; // 是否为红冲发票
      this.PIGUID = "";
      this.openFrom = "add";
      this.$nextTick(function() {
        this.invoiceTitle = "新增采购发票";
        this.invoiceVisible = true;
      });
    },

    // 保存表格设置
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("salesInvoice");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    // 重置表格设置
    onResetSetting() {
      localStorage.removeItem("salesInvoice");
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头隐藏列和设置列名
    onSaveConfig(column) {
      localStorage.removeItem("salesInvoice");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    }
  },
  async mounted() {
    await this.onInit();
  }
};
</script>

<style lang="scss">
.p-sales-invoice {
  .underline {
    text-decoration: underline;
    font-size: 12px;
  }
  .color-red {
    .znl-tablecell {
      color: red;
    }
    color: red;
  }
}
</style>


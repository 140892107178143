<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
      <FinInAndExpDetails
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          v-if='hasRight("FinInAndExpDetails")'
          @page-loading="(d)=>{pageLoading=d}"></FinInAndExpDetails>
  </znl-layout-spa>

</template>

<script>
import FinInAndExpDetails from '@/components/modules/Fin/FinInAndExpDetails_v1'

export default {
  name: 'VFinInAndExpDetails',
  components: {
    FinInAndExpDetails
  },
  data () {
    return {
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VFinInAndExpDetails'})
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div>
    <!-- 采购发票新增_编辑 -->
    <znl-dialog
      :title="title"
      :visible="visible"
      height="680px"
      width="1000px"
      class="dialog-invoice-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      :append-to-body="true"
      element-loading-text="数据加载中,请稍后..."
      @close="
        (val) => {
          $emit('close', val), onResetSalesInvoice();
        }
      "
    >
      <!-- 编辑内容 -->
      <div slot="znl-dialog">
        <el-row>
          <el-col :span="12">
            <znl-input
              title-width="70px"
              form-type="input"
              :disabled="false"
              placeholder=""
              size="mini"
              layout="left"
              :clearable="true"
              :border="true"
              title="*发票号："
              :is-must-fill="true"
              v-model="Invoice.InvoiceNumber"
              width="250px"
              type="text"
            >
            </znl-input>
          </el-col>
          <el-col :span="12" class="znl-text-right">
            <znl-input
              form-type="datepicker"
              title-width="70px"
              :disabled="false"
              placeholder="开票日期"
              title="*开票日期："
              size="mini"
              value-format="yyyy-MM-dd"
              layout="left"
              width="180px"
              :clearable="true"
              :is-must-fill="true"
              v-model="Invoice.InvoiceDate"
              :border="true"
            >
            </znl-input>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-around">
          <!-- 销售方 -->
          <el-col :span="12" class="input-box">
            <el-col :span="1" class="subtitle"> 销售方 </el-col>
            <el-col :span="23">
              <el-row>
                <company-optional
                  placeholder="请输入或选择销售方"
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="名称："
                  title-width="96px"
                  :is-must-fill="true"
                  dialog-title="选择销售方"
                  company-type="2,3"
                  open-from="invoice"
                  :value="Invoice.SellerName"
                  @confirm="onSellerConfirm"
                  width="100%"
                ></company-optional>
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="纳税人识别号："
                  title-width="96px"
                  v-model="Invoice.SellerTaxNumber"
                  width="274px"
                  type="text"
                >
                </znl-input>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="电话："
                  title-width="50px"
                  v-model="Invoice.SellerPhone"
                  width="171"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">纳税人识别号：</span>
              <span class="row-content">{{Invoice.SellerTaxNumber}}</span> -->
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="地址："
                  title-width="96px"
                  v-model="Invoice.SellerAddress"
                  width="100%"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">地址、电话：</span>
              <span class="row-content">{{Invoice.SellerAddress}} {{Invoice.SellerPhone}} </span> -->
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="开户银行："
                  title-width="96px"
                  v-model="Invoice.SellerBankName"
                  width="219"
                  type="text"
                >
                </znl-input>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="账号："
                  title-width="50px"
                  v-model="Invoice.SellerBankAccount"
                  width="226"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">开户行及账号：</span>
              <span class="row-content">{{Invoice.SellerBankName}} {{Invoice.SellerBankAccount}}</span> -->
              </el-row>
            </el-col>
          </el-col>

          <!-- 采购方 -->
          <el-col :span="12" class="input-box">
            <el-col :span="1" class="subtitle"> 采购方 </el-col>
            <el-col :span="23">
              <el-row>
                <sub-company-optional
                  placeholder="请输入或选择采购方"
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="名称："
                  title-width="96px"
                  :is-must-fill="true"
                  dialog-title="请选择采购方"
                  open-from="invoice"
                  :value="Invoice.BuyerName"
                  @confirm="onBuyerConfirm"
                  width="100%"
                ></sub-company-optional>
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="纳税人识别号："
                  title-width="96px"
                  v-model="Invoice.BuyerTaxNumber"
                  width="274px"
                  type="text"
                >
                </znl-input>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="电话："
                  title-width="50px"
                  v-model="Invoice.BuyerPhone"
                  width="171"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">纳税人识别号：</span>
              <span class="row-content">{{Invoice.BuyerTaxNumber}}</span> -->
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="地址："
                  title-width="96px"
                  v-model="Invoice.BuyerAddress"
                  width="100%"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">地址、电话：</span>
              <span class="row-content">{{Invoice.BuyerAddress}} {{Invoice.BuyerPhone}} </span> -->
              </el-row>

              <el-row>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="开户银行："
                  title-width="96px"
                  v-model="Invoice.BuyerBankName"
                  width="219"
                  type="text"
                >
                </znl-input>
                <znl-input
                  form-type="input"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :clearable="true"
                  :border="true"
                  title="账号："
                  title-width="50px"
                  v-model="Invoice.BuyerBankAccount"
                  width="226"
                  type="text"
                >
                </znl-input>
                <!-- <span class="row-title">开户行及账号：</span>
              <span class="row-content">{{Invoice.BuyerBankName}} {{Invoice.BuyerBankAccount}}</span> -->
              </el-row>
            </el-col>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="5">
            <znl-input
              title-width="70px"
              layout="left"
              form-type="input"
              :disabled="true"
              width="200px"
              placeholder=""
              popper-class="popperClass"
              title="价税合计："
              :border="true"
              :clearable="true"
              v-model="Invoice.InvoiceAmount"
            >
            </znl-input>
          </el-col>
          <el-col :span="5" class="znl-text-center2">
            <znl-input
              title-width="70px"
              layout="left"
              form-type="input"
              :disabled="true"
              width="200px"
              placeholder=""
              popper-class="popperClass"
              title="税额合计："
              :border="true"
              :clearable="true"
              v-model="Invoice.TaxAmount"
            >
            </znl-input>
          </el-col>

          <el-col :span="3" class="znl-text-center2">
            <znl-input
              title-width="50px"
              layout="left"
              form-type="select"
              width="110px"
              placeholder="请选择"
              popper-class="popperClass"
              title="税点："
              :border="true"
              :clearable="true"
              :select-options="selectOptionsTaxRate"
              @change="onTaxRateChange"
              v-model="Invoice.TaxRate"
            >
            </znl-input>
          </el-col>

          <el-col :span="7" class="znl-text-center2">
            <znl-input
              title-width="40px"
              layout="left"
              form-type="input"
              :disabled="false"
              width="100%"
              placeholder=""
              popper-class="popperClass"
              title="备注："
              :border="true"
              :clearable="true"
              v-model="Invoice.Remark"
            >
            </znl-input>
          </el-col>
          <el-col :span="4" class="znl-text-right">
            <znl-button
              style-type="minor"
              :height="22"
              :width="55"
              @click="invoiceSave(false)"
              v-show="!isRedRushInvoice"
              style="margin-left: 5px"
            >
              <span> <i class="iconfont icon-save_btn_ic"></i>保存</span>
            </znl-button>
            <znl-button
              style-type="main"
              @click="invoiceSave(true)"
              :height="22"
              style="margin-left: 5px"
            >
              <span> <i class="iconfont icon-save_btn_ic"></i>保存并关闭</span>
            </znl-button>
          </el-col>
        </el-row>
      </div>

      <div class="tab-right-button">
        <!-- <znl-button style-type="mac" :height='22' v-if="activeName === 'stkout-list'">
        <span><i class="iconfont icon-add_btn_ic"></i>添加发货清单</span>
      </znl-button>

      <znl-button style-type="mac" :height='22' v-if="activeName === 'in-list'">
        <span><i class="iconfont icon-add_btn_ic"></i>添加进项</span>
      </znl-button> -->
      </div>

      <el-tabs
        value="abc"
        type="border-card"
        v-model="activeName"
        class="nav-common-ui tab-btnstyle-box oustslide_padding-right"
        v-show="true"
        @tab-click="onTabClick"
      >
        <el-tab-pane label="发票清单" name="invoice-list">
          <line-list
            ref="invoiceLine"
            :res-rush="isRedRushInvoice"
            @set-tax-amount="(sum) => (Invoice.TaxAmount = sum)"
            @set-amount="(sum) => (Invoice.InvoiceAmount = sum)"
            @on-line-qty-changed="onLineQtyChanged"
          >
          </line-list>
        </el-tab-pane>

        <el-tab-pane label="入库清单" name="stkin-list">
          <stkin-list
            ref="stkinList"
            :customer-name="Invoice.SellerName"
            :tax-rate="Invoice.TaxRate"
            @add-invoice-line="addInvoiceLine"
          ></stkin-list>
        </el-tab-pane>

        <!-- <el-tab-pane label="发票清单" name="in-list" v-if="!isRedRushInvoice">
        <znl-gridmodule
          ref="flexGrid"
          height="323px"
          gridtype="action"
          layout-type="other"
          :has-znl-btns="false"
          element-loading-text="正在保存,请稍等..."
          :is-multi-rows-check-fixed="false"
          :columns="columns"
          :item-source="itemSource"
          :edit-row-index="editRowIndex"
          :column-colors="columnColors"
          :row-colors="rowColors"
          :users="$store.state.users"
          :loading="loading"
          @page-loading="d=>{$emit('page-loading', d)}"
        >
        </znl-gridmodule>
      </el-tab-pane> -->
      </el-tabs>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import LineList from "@c_modules/Fin/PurchaseInvoice/InvoiceEditLine";
import StkinList from "@c_modules/Fin/PurchaseInvoice/InvoiceEditStkin";
import CompanyOptional from "@c_modules/Control/CompanyInputOptional";
import SubCompanyOptional from "@c_modules/Control/SubCompanyInputOptional";

const CONFIG = {
  PK: "PILineID",
  configURL: "PurchaseInvoice/GetConfig",
  saveURL: "PurchaseInvoiceAdd/Save",
  getURL: "PurchaseInvoice/Get",
  getSubCompanyURL: "InvoiceTitleInfo/SearchSubCompany",
  searchStkinURL: "PurchaseInvoice/GetByStkIn", // 从入库单开票
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
};
export default {
  name: "PurchaseInvoiceEdit",
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin],
  components: {
    LineList,
    StkinList,
    CompanyOptional,
    SubCompanyOptional,
  },
  data() {
    return {
      columns: [],
      resourceList: [],
      itemSource: [],
      IncrementType: false,
      GeneralType: false,
      Invoice: {
        PIGUID: "",
        InvoiceNumber: "", // 发票编号
        InvoiceDate: new Date(), // 开票日期

        SellerID: "",
        SellerName: "",
        SellerBankName: "",
        SellerBankAccount: "",
        SellerTaxNumber: "",
        SellerAddress: "",
        SellerPhone: "",

        BuyerID: 0, // 我方公司开票信息ID
        BuyerName: "", // 客户开票信息
        BuyerBankName: "", // 我方银行名称
        BuyerBankAccount: "", // 我方银行账号
        BuyerTaxNumber: "", // 我方纳税人识别号
        BuyerAddress: "", // 我方地址
        BuyerPhone: "", // 我方电话

        WriteOffStatus: 1,
        InvoiceAmount: "", // 价税合计
        TaxAmount: "", // 税额合计
        InvoiceType: "", // 开票类型
        Remark: "", // 备注
        CreatedByName: "", // 制单人
        CreatedTime: "", // 制单时间

        LineList: [],
        StkinList: [], // 发货清单
        EntryList: [], // 进项清单
      },
      InvoiceDefault: {}, // 默认数据
      defBuyerID: 0, // 默认的采购方id(上次保存的数据)
      InvoiceType: "",
      isRushRed: false,
      totalCount: 0,
      editRowIndex: -1,
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      loading: false,
      isAdd: true,
      dialogLoading: true,
      ParamJosn: [],
      activeName: "invoice-list",
    };
  },
  props: {
    title: String, // 接收显示组件头
    visible: {
      // 接收显示组件
      type: Boolean,
      defaule: () => {
        return false;
      },
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    PIGUID: String,
    openFrom: String,
    isRedRushInvoice: {
      // 是否是冲红发票
      type: Boolean,
      defaule: false,
    },
    stkInGuids: Array, // 发货单guid.
    stkInLineGuids: {
      type: Array,
      defaule: () => {
        return [];
      },
    },
  },
  watch: {
    IncrementType() {
      if (this.IncrementType) {
        this.GeneralType = false;
        this.Invoice.InvoiceType = 1;
      }
      if (!this.GeneralType && !this.IncrementType) {
        this.Invoice.InvoiceType = "";
      }
    },
    GeneralType() {
      if (this.GeneralType) {
        this.IncrementType = false;
        this.Invoice.InvoiceType = 2;
      }
      if (!this.GeneralType && !this.IncrementType) {
        this.Invoice.InvoiceType = "";
      }
    },
  },
  methods: {
    // 初始化
    async onInit() {
      let config = await this.isSaveStorage(
        "PurchaseInvoice",
        CONFIG.configURL
      );
      // 获取主单参数配置
      this.ParamJosn = config.ParamJosn;
      this.resourceList = config.ResourceList.map((item) => item.Code);
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);

      this.pageSize = config.PageSize;
      this.columns = columns;

      if (this.openFrom === "return-order") {
        // 从退货单点击。生成的发票为红冲发票.
        this.isAdd = true;
        await this.loadByStkin();
      } else if (this.openFrom === "wait-invoice") {
        // 从采购未开票打开
        this.isAdd = true;
        await this.loadByStkin();
      } else if (this.hasValue(this.PIGUID)) {
        // 编辑
        this.isAdd = false;
        this.onBindData();
      } else {
        this.$nextTick(() => {
          this.dialogLoading = false;
        });
      }
      this.isAdd && this.getDefaultPurchaseCompany();
    },

    // 获取参数配置
    getDefaultPurchaseCompany(companyName) {
      if (this.Invoice.BuyerID && this.Invoice.BuyerID > 0) {
        return true;
      }
      let param = {
        SubCompanyID: null,
        CompanyName: companyName || "",
      };

      let list = this.$refs.stkinList.itemSource;
      // 查询采购公司
      if (param.CompanyName === "" || (list && list.length > 0)) {
        _.find(list, (stk) => {
          if (stk.PurchaseCompany && stk.PurchaseCompany !== "") {
            param.CompanyName = stk.PurchaseCompany;
            return true;
          }
        });
      }

      // 如果没有指定公司名，则从参数配置，获取上次选择的采购公司
      if (
        param.CompanyName === "" ||
        param.CompanyName === null ||
        param.CompanyName === undefined
      ) {
        // 默认采购公司
        let defBuyer = _.find(
          this.ParamJosn,
          (data) => data && data.ParamCode === "DefaultPurchaseCompany"
        );
        if (defBuyer && !isNaN(defBuyer.ParamValue)) {
          param.SubCompanyID = Number(defBuyer.ParamValue);
          this.defBuyerID = param.SubCompanyID;
        }
      }

      if (param.CompanyName || param.SubCompanyID) {
        this.$post(CONFIG.getSubCompanyURL, param, (data) => {
          if (data && data.length > 0) {
            let item = data[0];
            this.Invoice.BuyerID = item.SubCompanyID;
            this.Invoice.BuyerName = item.CompanyName;
            this.Invoice.BuyerBankName = item.BankName;
            this.Invoice.BuyerBankAccount = item.BankAccount;
            this.Invoice.BuyerAddress = item.Address;
            this.Invoice.BuyerPhone = item.Phone;
            this.Invoice.BuyerTaxNumber = item.TaxNumber;
          }
        });
      }
    },

    onTabClick() {},

    // 绑定数据
    onBindData() {
      let _this = this;
      this.$post(
        CONFIG.getURL,
        { PIGUID: this.PIGUID },
        (data, logic) => {
          if (logic.code === 200) {
            this.Invoice = data;
            this.$refs.invoiceLine.itemSource = data.LineList;
            this.$refs.stkinList.itemSource = data.StkinList;
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
          _this.dialogLoading = false;
        },
        () => {
          _this.dialogLoading = false;
        }
      );
    },

    // 从入库单加载数据，冲红发票
    async loadByStkin() {
      this.dialogLoading = true;
      let param = { BillsType: 1 };
      if (this.isRedRushInvoice) {
        param.BillsType = 2;
      }
      if (this.stkInLineGuids && this.stkInLineGuids.length > 0) {
        param.StkInLineGuids = this.stkInLineGuids;
      }
      if (this.stkInGuids && this.stkInGuids.length > 0) {
        param.StkInGuids = this.stkInGuids;
      }
      try {
        let data = await this.$post(CONFIG.searchStkinURL, param);
        if (data.LineList) {
          _.each(data.LineList, (line, index) => {
            line.SILineID = -(index + 1000);
          });
        }
        this.$refs.invoiceLine.itemSource = data.LineList;
        this.$refs.stkinList.itemSource = data.StkinList;
        this.Invoice.TaxRate = data.StkinList[0].TaxRate;

        this.Invoice.SellerAddress = data.SellerAddress;
        this.Invoice.SellerBankAccount = data.SellerBankAccount;
        this.Invoice.SellerBankName = data.SellerBankName;
        this.Invoice.SellerID = data.SellerID;
        this.Invoice.SellerName = data.SellerName;
        this.Invoice.SellerPhone = data.SellerPhone;
        this.Invoice.SellerTaxNumber = data.SellerTaxNumber;
        this.Invoice.WriteOffStatus = 1;
        this.Invoice.BillsType = param.BillsType;
        this.Invoice.InvoiceAmount = data.InvoiceAmount;
        this.Invoice.TaxAmount = data.TaxAmount;
        this.dialogLoading = false;
      } catch (err) {
        console.log(err);
        this.dialogLoading = false;
      }
    },

    // 保存
    invoiceSave(isClose) {
      this.Invoice.LineList = this.$refs.invoiceLine.itemSource;
      this.Invoice.StkinList = this.$refs.stkinList.itemSource;
      if (this.CheckData()) {
        var vm = this;
        vm.loading = true;
        return this.$post(CONFIG.saveURL, this.Invoice, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.Invoice = {};
            this.Invoice = data;
            this.$message({ message: "保存成功", type: "success" });
            this.$emit("clear-Selected"); //清除选中行
            if (isClose) {
              this.$emit("save-success", true);
              this.$emit("close");
            } else {
              this.onResetSalesInvoice();
            }
            if (this.Invoice.BuyerID !== this.defBuyerID) {
              this.onSaveParam();
            }
          }
          vm.loading = false;
        }).finally(() => {
          vm.loading = false;
        });
      }
    },

    // 保存默认采购公司和默认的发票类型
    onSaveParam() {
      _.each(this.ParamJosn, (item) => {
        if (item.ParamCode === "DefaultSalesCompany") {
          item.ParamValue = this.Invoice.BuyerID;
        }
        // if (item.ParamCode === 'DefaultInvoiceType') {
        //   item.ParamValue = this.Invoice.InvoiceType
        // }
      });
      if (this.hasValue(this.ParamJosn)) {
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "SalesInvoice", ParamJson: this.ParamJosn },
          (data) => {
            localStorage.removeItem("salesInvoice");
          }
        );
      }
    },

    CustNameChange(queryString) {
      this.$post(
        "CompanyContacts/SearchCompanyContacts",
        {
          FieldWhereString: JSON.stringify({
            CCompanyID: queryString,
          }),
          PageIndex: 1,
          PageSize: 20,
          CompanyTypes: "1,3",
        },
        (data) => {
          if (this.hasValue(data.ResultList)) {
            this.Invoice.CustInvoiceInfo = data.ResultList[0].BillContent;
          }
        }
      );
    },

    CheckData() {
      // 检查数据的正确性
      if (this.Invoice.InvoiceNumber === "") {
        this.$message({ message: "请填写发票编号", type: "error" });
        return false;
      }
      if (this.Invoice.InvoiceDate === null) {
        this.$message({ message: "请选择开票日期", type: "error" });
        return false;
      }
      if (this.Invoice.SellerName === "") {
        this.$message({ message: "请选择销售方", type: "error" });
        return false;
      }
      if (this.Invoice.BuyerName === "") {
        this.$message({ message: "请选择采购方", type: "error" });
        return false;
      }
      // if (this.Invoice.InvoiceAmount === '') {
      //   this.$message({ message: '发票金额不能为空', type: 'error' })
      //   return false
      // }
      // if (!this.GeneralType && !this.IncrementType) {
      //   this.$message({ message: '请选择开票类型', type: 'error' })
      //   return false
      // }
      // if (this.Invoice.CustInvoiceInfo === '') {
      //   this.$message({ message: '请录入开票信息', type: 'error' })
      //   return false
      // }

      // 检查发票清单数据
      if (!this.lineListCheck()) {
        return false;
      }
      return true;
    },

    // 检查发票清单数据
    lineListCheck() {
      let hasError = false;
      let newArry = [];
      let qtyMoreThenZero = !this.isRedRushInvoice;
      if (this.Invoice.LineList && this.Invoice.LineList.length > 0) {
        // 清除空行
        _.each(this.Invoice.LineList, (item, index) => {
          if (hasError) {
            return true;
          }
          let isEmptyRow =
            !this.hasValue(item.ProductCategory) &&
            !this.hasValue(item.Model) &&
            !this.hasValue(item.Unit) &&
            !this.hasValue(item.Qty) &&
            !this.hasValue(item.Price) &&
            !this.hasValue(item.Amount) &&
            !this.hasValue(item.TaxRate) &&
            !this.hasValue(item.TaxAmount);
          if (isEmptyRow) {
            console.log(
              "is empty row",
              index,
              !this.hasValue(item.Price),
              item
            );
            return true;
          }
          // console.log(item, index, 454555545, !this.hasValue(item.Model))
          if (!this.hasValue(item.Model)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 型号不能为空",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.hasValue(item.Qty)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 数量不能为空",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.numberCheck(item.Qty, qtyMoreThenZero)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 数量需要为数字",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.hasValue(item.Price)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 单价不能为空",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.numberCheck(item.Price)) {
            this.$message({
              message:
                "发票清单，第" + (index + 1) + "行, 单价需要为大于0的数字",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.hasValue(item.Amount)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 金额不能为空",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.numberCheck(item.Amount, qtyMoreThenZero)) {
            this.$message({
              message:
                "发票清单，第" + (index + 1) + "行, 金额需要为大于0的数字",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.hasValue(item.TaxRate)) {
            this.$message({
              message: "发票清单，第" + (index + 1) + "行, 税率不能为空",
              type: "warning",
            });
            hasError = true;
            return true;
          }
          if (!this.numberCheck(item.TaxRate)) {
            this.$message({
              message:
                "发票清单，第" + (index + 1) + "行, 税率必须为大于0的数字",
              type: "warning",
            });
            hasError = true;
            return true;
          }

          newArry.push(item);
        });
        this.Invoice.LineList = newArry;
      }
      if (hasError) {
        return false;
      }
      if (newArry.length === 0) {
        this.$message({ message: "请输入发票清单数据", type: "error" });
        return false;
      }
      return true;
    },

    numberCheck(val, moreThanZero = true) {
      if (val !== null && val !== undefined && !isNaN(val)) {
        if (!moreThanZero) {
          return true;
        }
        if (parseFloat(val) >= 0) {
          return true;
        }
      }
      return false;
    },

    onTaxRateChange(item) {
      this.$refs.invoiceLine &&
        this.$refs.invoiceLine.onTaxRateChange(item.key);
    },

    // 重置页面 清除数据
    onResetSalesInvoice() {
      this.Invoice = _.extend({}, this.InvoiceDefault);
      this.isAdd = true;
      this.$refs.invoiceLine.itemSource = [];
      this.$refs.stkinList.itemSource = [];
    },

    hasRes(code) {
      return _.some(this.resourceList, (page) => page.Code === code);
    },

    // 选择销售方信息
    onSellerConfirm(item = null) {
      if (item === null) {
        item = {
          CCompanyID: "",
          CompanyName: "",
          BankName: "",
          BankName: "",
          Address: "",
          TelePhone: "",
          TaxNumber: "",
        };
      }
      this.Invoice.SellerID = item.CCompanyID;
      this.Invoice.SellerName = item.CompanyName;
      this.Invoice.SellerBankName = item.BankName;
      this.Invoice.SellerBankAccount = item.BankAccount;
      this.Invoice.SellerAddress = item.Address;
      this.Invoice.SellerPhone = item.TelePhone;
      this.Invoice.SellerTaxNumber = item.TaxNumber;
      this.testSellerInvoiceNumber = this.Invoice.SellerTaxNumber;
      console.log(this.Invoice.SellerTaxNumber, "SellerTaxNumber");
    },

    // 选择采购方
    onBuyerConfirm(item = null) {
      if (item === null) {
        item = {
          SubCompanyID: "",
          CompanyName: "",
          BankName: "",
          BankAccount: "",
          Address: "",
          Phone: "",
          TaxNumber: "",
        };
      }
      this.Invoice.BuyerID = item.SubCompanyID;
      this.Invoice.BuyerName = item.CompanyName;
      this.Invoice.BuyerBankName = item.BankName;
      this.Invoice.BuyerBankAccount = item.BankAccount;
      this.Invoice.BuyerAddress = item.Address;
      this.Invoice.BuyerPhone = item.Phone;
      this.Invoice.BuyerTaxNumber = item.TaxNumber;
    },

    // 添加到发票清单
    addInvoiceLine(row) {
      this.getDefaultPurchaseCompany();
      this.$refs.invoiceLine.addLine(row);
    },

    onLineQtyChanged(afterValue, beforeValue, stkInLineGuid) {
      this.$refs.stkinList.onInvoiceListQtyChanged(
        afterValue,
        beforeValue,
        stkInLineGuid
      );
    },
  },
  mounted() {
    this.InvoiceDefault = _.extend({}, this.Invoice);
    this.onInit();
  },

  created() {
    this.dialogLoading = true;
  },
};
</script>
<style lang="scss">
.dialog-invoice-box {
  .el-row {
    margin: 2px 0;
  }
  .input-box:first-child {
    margin-right: 5px;
  }
  .input-box {
    border: 1px solid #d8d8d8;
    padding: 2px 8px;
    .subtitle {
      padding-top: 17px;
      border-right: 1px solid #d8d8d8;
      padding-right: 10px;
      height: 100%;
    }
  }
  .is-disabled {
    input {
      border: 1px solid #e8e8e8 !important;
    }
  }
  .row-title,
  .row-content {
    font-size: 12px;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
  }
  .row-title {
    padding-left: 6px;
    text-align: right;
    width: 90px;
  }
  .row-content {
    width: 351px;
    color: #0080ff;
  }

  .nav-common-ui {
    border: 0;
    margin-top: 7px;
  }

  .tab-btnstyle-box > .el-tabs__header {
    background: #fff;
    // border: 0;
    .el-tabs__item.is-active,
    .el-tabs__item {
      border-right: 0;
      border-bottom: 0;
    }
  }
  .tab-btnstyle-box {
    height: auto;
    background: #fff;

    .el-tabs__content {
      margin: 0;
      padding: 0;
      border-top: 0;
      .el-tab-pane .znl-grid-component {
        background: #fff;
        .znl-content {
          // border: 0;
          margin: 0px 0px 0px 4px;
          border-top: 0;
          border-bottom: 1px solid #d8d8d8 !important;
        }
      }
    }
  }

  .tab-right-button {
    position: absolute;
    text-align: right;
    width: 98%;
    z-index: 1;
    margin-top: 5px;
  }

  .actionBtns {
    margin-left: 7px;
  }
}
</style>


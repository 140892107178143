<template>
  <!-- 采购未开票 -->
  <div class="module-wrapper m-purchase-wait-invoice">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :is-fields-search="isShowFiedsSearch"
      :showCheck="true"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      :search-fields="onSearch"
      :totalField="['Amount', 'NoInvoiceAmount', 'Qty']"
      @select-change="selectChange"
      checkboxBindingKey="StkInLineGUID"
      @page-loading="
        (d) => {
          $emit('page-loading', d);
        }
      "
    >
      <div slot="header" class="v-table-toolbar">
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            title-width="60px"
            width="220px"
            placeholder="供应商名称"
            class="box_input_checkbox"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="fieldsFilter.SupplierName"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox
            v-model="fieldsFilter.IsFullMatch"
            class="accurate-checbox"
            size="small"
            >精确</el-checkbox
          >
        </div>

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="型号"
          size="mini"
          layout="left"
          title-width="30px"
          width="150px"
          inp-perc="100%"
          :border="true"
          :clearable="true"
          v-model="fieldsFilter.Model"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="入库单号"
          size="mini"
          layout="left"
          title-width="60px"
          width="120px"
          inp-perc="100%"
          :border="true"
          :clearable="true"
          v-model="fieldsFilter.BillNo"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="入库时间从"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          title-width="60px"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="fieldsFilter.ReceiveTimeStart"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="入库时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="fieldsFilter.ReceiveTimeEnd"
        ></znl-input>

        <znl-input
          title-width="70px"
          ref="SalerName"
          layout="left"
          form-type="select"
          :disabled="false"
          width="120px"
          placeholder="采购员"
          :clearable="true"
          :border="true"
          :select-options="selectOptionsAllUserURL"
          v-model="outerFieldsFilter.BuyerId"
        ></znl-input>

        <znl-button
          type="multiple"
          :height="22"
          :width="70"
          class="box-left-btn search-btn"
          style-type="mac"
        >
          <znl-button-menu @click="onSearch()">
            <span> <i class="iconfont icon-search_ic"></i>搜索 </span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <!-- <znl-button style-type="main" @click="onBatchInvoice" v-if="true" :height="22">
          <span>收票登记</span>
        </znl-button>-->
        <znl-button
          style-type="mac"
          @click="onBatchInvoice"
          tip="点击向下三角符号，可按整张单收票"
          :btns="invoiceBtns"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>收票登记</span>
        </znl-button>
        <znl-button
          style-type="mac"
          :btns="exportConfig.btns"
          :height="22"
          @click="onExport"
          tip="按搜索条件导出"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <a
          style="
            text-decoration: underline;
            margin-left: 3px;
            margin-right: 3px;
          "
          title="点击查看"
          @click="onCheckInfo()"
          >已选择 ({{ selectedRows.length }}) 条</a
        >
        <a
          style="text-decoration: underline; margin-left: 5px"
          title="清除选中条数"
          @click="onCleaSelectCount()"
          >清除</a
        >
      </div>
    </znl-table>
    <!-- 采购发票新增_编辑 -->
    <invoice-edit
      v-if="invoiceVisible"
      :visible="invoiceVisible"
      :title="invoiceTitle"
      open-from="wait-invoice"
      :stk-in-guids="stkInGuids"
      :stk-in-line-guids="stkInLineGuids"
      :is-red-rush-invoice="isReturninvoice"
      @close="invoiceVisible = false"
      @save-success="onRefresh"
      @clear-Selected="onCleaSelectCount"
    ></invoice-edit>
    <export-column
      :visible="exportConfig.setExportColumnVisible"
      :addColumns="exportConfig.addColumnsList"
      :addConfigColumns="exportConfig.setConfigColumns"
      :saveConfigURL="exportConfig.setSaveConfigURL"
      v-if="exportConfig.setExportColumnVisible"
      title="设置导出列"
      @close="
        () => {
          exportConfig.setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          exportConfig.setExportColumnVisible = false;
        }
      "
    ></export-column>

    <!--已选择采购未收票列表-->
    <WaitInvoiceCheckInfo
      v-if="showWaitInvoiceCheckInfo"
      ref="WaitInvoiceCheckInfo"
      :visible="showWaitInvoiceCheckInfo"
      showSupplierName
      @checkInfo="
        (val) => {
          this.onSelectedInfo(val);
        }
      "
      @close="
        () => {
          showWaitInvoiceCheckInfo = false;
        }
      "
    ></WaitInvoiceCheckInfo>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import InvoiceEdit from "@c_modules/Fin/PurchaseInvoice/InvoiceEdit";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";
import WaitInvoiceCheckInfo from "@c_modules/Fin/WaitInvoiceCheckInfo";

const CONFIG = {
  PK: "StkInLineGUID",
  configKey: "PurchaseWaitInvoice",
  configURL: "PurchaseWaitInvoice/GetConfig",
  saveConfigURL: "PurchaseWaitInvoice/SaveConfig",
  resetConfigURL: "PurchaseWaitInvoice/ResetConfig",
  searchURL: "PurchaseWaitInvoice/Search",
};
export default {
  name: "PurchaseWaitInvoice",
  mixins: [znlMethodsMixin, getCommonDataMixin, exportData],
  config: CONFIG,
  // 组件
  components: {
    InvoiceEdit,
    ExportColumn,
    WaitInvoiceCheckInfo,
  },
  data() {
    return {
      isShowFiedsSearch: false,
      isReturninvoice: false,
      columns: [],
      itemSource: [],
      waitInvoiceSelectItem: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，,
      gridShow: false,
      selectedRows: [], // 选中的行
      billsTypeOptions: [
        { key: "正常", value: 1 },
        { key: "红冲", value: 2 },
      ],
      fieldsFilter: {
        ReceiveTimeStart: null,
        ReceiveTimeEnd: null,
        Model: "",
        SupplierName: "",
        IsFullMatch: false,
        BillNo: "",
      },
      outerFieldsFilter: {
        BuyerId: null,
      },
      parameterConfig: [],
      loading: false,
      invoiceVisible: false,
      invoiceTitle: "",
      stkInLineGuids: [],
      stkInGuids: [],
      invoiceBtns: [
        {
          name: "按整张单收票",
          click: () => {
            this.onInvoiceByOrder();
          },
        },
      ],
      exportConfig: {
        btns: [
          {
            name: "设置导出列",
            click: () => {
              this.showExportColumn();
            },
          },
        ],
        setExportColumnVisible: false,
        addColumnsList: [],
        setConfigColumns: {},
        setSaveConfigURL: "",
        searchCondition: {},
      },
      showWaitInvoiceCheckInfo: false, // 是否显示已选择数据列表
    };
  },
  watch: {
    selectOptionsTaxRate(val) {
      _.each(this.columns, (column) => {
        if (column.binding === "TaxRate") {
          this.$set(column, "dataMap", val);
        }
      });
    },
    selectOptionsQtyUnit(val) {
      _.each(this.columns, (column) => {
        if (column.binding === "Unit") {
          this.$set(column, "dataMap", val);
        }
      });
    },
  },
  // 父组件传过来的参数
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
  },
  // 方法
  methods: {
    onInit: async function (config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage(CONFIG.configKey, CONFIG.configURL);
      }
      this.resourceList = config.ResourceList.map((item) => item.Code);
      let columns = config.ColumnConfigs;
      this.parameterConfig = config.ParamJosn;

      this.columns = this.settingColumns(columns);
      this.defaultConfig = config;
      this.pageSize = config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$nextTick(() => {
        this.$emit("grid-show", true);
        this.$emit("page-loading", false);
      });
    },
    onSelectedInfo(val) {
      this.selectedRows = val;
    },
    // 查看已选中的数据
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: "至少选择一条数据", type: "warning" });
      } else {
        _.each(this.selectedRows, (item) => {
          item.CheckGUID = item.StkInLineGUID;
        });
        let order = { checkInfo: this.selectedRows };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function () {
          this.showWaitInvoiceCheckInfo = true;
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    // 单行开票操作
    onInvoice(row) {
      let guids = [];
      guids.push(row.StkInLineGUID);
      this.isReturninvoice = row.BillsType !== 1; // 是否冲红发票
      this.onInvoiceOrder(guids);
    },

    // 按整张单开票
    onInvoiceByOrder() {
      let msg = "";
      if (
        this.selectedRows === null ||
        this.selectedRows === undefined ||
        this.selectedRows.length === 0
      ) {
        return this.$message({
          message: "请选择一个单据，然后再进行收票登记",
          type: "warning",
        });
      } else {
        let guid = "";
        _.each(this.selectedRows, (item) => {
          if (guid === "") {
            guid = item.StkInGUID;
          } else if (guid !== item.StkInGUID || msg !== "") {
            msg = "请选择一个单据，然后再进行收票登记";
          }
        });
        if (msg !== "") {
          return this.$message({ message: msg, type: "warning" });
        }
      }
      let row = this.selectedRows[0];
      this.isReturninvoice = row.BillsType !== 1; // 是否冲红发票
      this.onInvoiceOrder(null, [row.StkInGUID]);
    },

    // 批量开票操作
    onBatchInvoice() {
      let rows = this.selectedRows;
      let flag = true;
      let lineGuids = [];
      let custName = null;
      let isContrastCust = true;
      let isConType = true;
      let billsType = null;
      let taxRate = null;
      let isSameTaxRate = true;
      if (rows === null || rows === undefined || rows.length <= 0) {
        return this.$message({
          message: "请勾选上要开发票的数据行!",
          type: "warning",
        });
      }
      _.each(rows, (item) => {
        if (custName === null) {
          custName = item.SupplierName;
        }
        if (custName !== item.SupplierName) {
          isContrastCust = false;
          return;
        }
        if (billsType === null) {
          billsType = item.BillsType;
        }
        if (billsType !== item.BillsType) {
          isConType = false;
          return;
        }
        if (taxRate === null) {
          taxRate = item.TaxRate;
        }
        if (taxRate !== item.TaxRate) {
          isSameTaxRate = false;
          return true;
        }

        if (
          this.hasValue(item.TicketAmount) &&
          this.hasValue(item.Amount) &&
          item.TicketAmount >= item.Amount
        ) {
          this.$message({
            message: "单号: " + item.BillNo + " 已全部开票，不可再开票",
            type: "warning",
          });
          flag = false;
          return;
        } else {
          lineGuids.push(item.StkInLineGUID);
        }
      });
      if (!isConType) {
        return this.$message({
          message: "请选择相同的开票类型,再操作!",
          type: "warning",
        });
      }
      if (!isContrastCust) {
        return this.$message({
          message: "请选择相同的供应商名称,再操作!",
          type: "warning",
        });
      }
      if (!isSameTaxRate) {
        return this.$message({
          message: "请选择相同税率的数据，在进行操作",
          type: "warning",
        });
      }
      if (flag) {
        this.isReturninvoice = rows[0].BillsType !== 1; // 是否冲红发票
        this.onInvoiceOrder(lineGuids);
      }
    },

    // 获取按钮权限
    hasRes(code) {
      if (code.length === 0) {
        return false;
      }
      return _.contains(this.resourceList, code);
    },
    settingColumns(columns) {
      let mouseBtn = [
        {
          title: "收票登记",
          name: "takeTicket",
          click: (row) => {
            this.onInvoice(row);
          },
        },
      ];

      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
        },
      ];

      // 单据状态
      let billsTypeCol = _.find(columns, (column) => {
        return column.BindField === "BillsType";
      });
      if (billsTypeCol) {
        _.extend(billsTypeCol, {
          type: "select",
          width: 70,
          options: this.billsTypeOptions,
          isReadOnly: true,
        });
      }

      return tempConfig.concat(columns);
    },
    // 1.打开采购发票编辑详情 2. 销售退货单的发票判定为冲红发票入口
    onInvoiceOrder(guids = null, stkInGuids = null) {
      this.stkInLineGuids = guids;
      this.stkInGuids = stkInGuids;
      this.$nextTick(function () {
        this.invoiceTitle = "编辑采购发票";
        this.invoiceVisible = !this.invoiceVisible;
      });
    },

    onSearch(params = null) {
      if (params) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      this.log(params, "123456", this.fieldsFilter);
      return this.onBindData(1);
    },
    // 刷新
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    // 高级搜索 搜索栏的显示和隐藏
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    // 访问接口,绑定数据
    onBindData(pageIndex = 1) {
      this.loading = true;
      let buyerName = (
        _.find(
          this.selectOptionsAllUserURL,
          (a) => a.key === this.outerFieldsFilter.BuyerId
        ) || {}
      ).value;
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          ShipTimeStart: this.fieldsFilter.ShipTimeStart,
          ShipTimeEnd: this.fieldsFilter.ShipTimeEnd,
          Model: this.fieldsFilter.Model,
          BillNo: this.fieldsFilter.BillNo,
          BuyerName: buyerName,
        }
      );
      this.waitInvoiceSelectItem = null;
      this.$post(CONFIG.searchURL, binddata, (data) => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("grid-show", true);
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },

    // 勾选选择框事件
    selectChange(rows) {
      if (this.selectedRows.length <= 0) {
        this.selectedRows = rows;
      } else {
        // 先把勾选的添加进集合
        _.each(rows, (row) => {
          if (
            !_.some(
              this.selectedRows,
              (a) => a.StkInLineGUID === row.StkInLineGUID
            )
          ) {
            this.selectedRows.push(row);
          }
        });

        // 再把当前页没有勾选的从集合移除
        _.each(this.itemSource, (row) => {
          // 当前页的数据 不在已选中的列表里面，但是在 selectedRows 里面，则需要移除
          if (!_.some(rows, (a) => a.StkInLineGUID === row.StkInLineGUID)) {
            let index = _.findIndex(
              this.selectedRows,
              (b) => b.StkInLineGUID === row.StkInLineGUID
            );
            if (index > -1) {
              this.selectedRows.splice(index, 1);
            }
          }
        });
      }
    },

    showExportColumn() {
      this.exportConfig.addColumnsList = this.columns;
      this.exportConfig.setConfigColumns = this.defaultConfig;
      this.exportConfig.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.exportConfig.setExportColumnVisible = true;
    },

    async onExport() {
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
        },
        {
          // 外部搜索条件
          ShipTimeStart: this.fieldsFilter.ShipTimeStart,
          ShipTimeEnd: this.fieldsFilter.ShipTimeEnd,
          Model: this.fieldsFilter.Model,
          BillNo: this.fieldsFilter.BillNo,
        }
      );
      this.loading = true;
      this.loading = await this.exportData(
        "PurchaseWaitInvoice",
        binddata,
        this.columns,
        "采购未开票"
      );
    },

    // 保存表格设置
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem(CONFIG.configKey);
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    // 重置表格设置
    onResetSetting() {
      localStorage.removeItem(CONFIG.configKey);
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头隐藏列和设置列名
    onSaveConfig(column) {
      localStorage.removeItem(CONFIG.configKey);
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
  },
  async mounted() {
    await this.onInit();
  },
};
</script>

<style lang="scss">
</style>


<template>
  <div class="p-fin-pay-in-add">
    <!--编辑/新增预付款-->
    <znl-dialog
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      :element-loading-text="LoadingText"
      :title="title"
      :visible="visible"
      class="dialog-finpayin-add-box p-fin-pay-in-add_dialog"
      height="390px"
      width="600px"
      @close="()=>{$emit('close')}"
    >
      <div slot="znl-dialog" class="row-box">
        <el-row class="form_Row_W100">
          <el-col>
            <znl-input
              v-model="advancePay.BankAccountGUID"
              :border="true"
              :clearable="true"
              :disabled="isEdit"
              :is-must-fill="true"
              :select-options="selectOptionsBankAccountAndName"
              form-type="select"
              layout="left"
              placeholder="请选择"
              size="mini"
              title="账户："
              title-width="70px"
              type="text"
              width="46%"
              @change="setBankAccountBalance"
            ></znl-input>
            <!--  -->
            <span style="color:red;margin-left:5px;">余额：</span>
            <span>{{ advancePay.Balance }}</span>
          </el-col>
        </el-row>
        <el-row class="form_Row_W100">
          <el-col>
            <el-row class="form_Row_W100">
              <znl-input
                v-model="advancePay.BusinessDate"
                :border="true"
                :clearable="true"
                :disabled="isEdit"
                :is-must-fill="true"
                form-type="datepicker"
                layout="left"
                placeholder="付款时间"
                size="mini"
                title="付款时间："
                title-width="70px"
                width="46%"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <!--第一列 -->
          <el-col :span="11">
            <el-row class="form_Row_W100">
              <znl-input
                v-model="advancePay.CompanyName"
                :border="true"
                :clearable="true"
                :disabled="isEdit"
                :is-must-fill="true"
                :on-icon-click="SearchCompany"
                form-type="input"
                layout="left"
                placeholder
                size="mini"
                title="供应商："
                title-width="70px"
                type="text"
                width="100%"
              >
                <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
              </znl-input>
            </el-row>

            <el-row class="form_Row_W100">
              <el-col>
                <znl-input
                  v-model="advancePay.Expenditure"
                  :border="true"
                  :clearable="true"
                  :disabled="false"
                  :is-must-fill="true"
                  form-type="input"
                  layout="left"
                  placeholder
                  size="mini"
                  title="预付金额："
                  title-width="70px"
                  type="text"
                  width="100%"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>

          <!--第二列 -->
          <el-col :span="23">
            <el-row class="form_Row_W100">
              <znl-input
                v-model="advancePay.Abstract"
                :border="true"
                :clearable="true"
                :disabled="false"
                :rows="5"
                form-type="input"
                layout="left"
                placeholder="备注"
                size="mini"
                title="备注："
                title-width="70px"
                type="textarea"
                width="100%"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row slot="footer" align="middle" class="dialog-footer" justify="end" style="width: 100%" type="flex">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-checkbox v-show="!isEdit" v-model="isSaveAndContinue" style="margin-right:5px"></el-checkbox>
          <label v-show="!isEdit" for="continueSave" style="margin-right:3px;">保存后继续操作</label>
          <znl-button :height="30" :width="60" style="margin-right:10px" @click="onClear">关闭</znl-button>
          <znl-button
            :disabled="dialogLoading"
            :height="30"
            :width="60"
            style-type="main"
            @click="onSave"
          >保存
          </znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <d-company-contact-select
      :companyType="InTypes"
      :visible="dialogCompanySelectVisible"
      title="选择公司名称"
      @close="()=>{dialogCompanySelectVisible=false}"
      @confirm="companyChecked"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
    ></d-company-contact-select>
  </div>
</template>
<script>
import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import {mixin as getCommonDataMixin} from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  advancePayAddURL: "FinPayInAdvance/Add",
  advancePayEditURL: "FinPayInAdvance/Edit",
  advancePayGetURL: "FinPayInAdvance/Get",
  getBankAccountsURL: "FinBankAccount/GetAccounts"
};

const defaultAdvancePay = {
  RPGUID: "",
  BillType: "",
  CCompanyID: "",
  CompanyName: "",
  Expenditure: "", // 金额，支出
  BankAccountNo: "", // 账户号码
  BankAccountName: "", // 账户名称
  BankAccountGUID: "",
  BusinessDate: null, // 付款时间
  CreatedByName: "", // 录入人
  CreatedGUID: "",
  Balance: 0,
  Abstract: "" // 备注
};
export default {
  name: "FinPayinAdd",
  mixins: [getCommonDataMixin],
  components: {
    dCompanyContactSelect
  },
  data() {
    return {
      isEdit: false,
      advancePay: {
        // 预付款对象
        RPGUID: "",
        BillType: "",
        CCompanyID: "",
        CompanyName: "",
        Expenditure: "", // 金额，支出
        BankAccountNo: "", // 账户号码
        BankAccountName: "", // 账户名称
        BankAccountGUID: "",
        BusinessDate: null, // 付款时间
        CreatedByName: "", // 录入人
        CreatedGUID: "",
        Balance: 0,
        Abstract: "" // 备注
      },
      isSaveAndContinue: false,
      InTypes: "", // 1:客户，2：供应商，3:两者都是的公司
      dialogCompanySelectVisible: false,
      dialogLoading: false,
      LoadingText: "",
      bankAccounts: []
    };
  },
  props: {
    RPGUID: String, // 库存GUID
    isRefresh: Boolean, // 是否刷新
    title: String,
    visible: Boolean
  },
  watch: {
    RPGUID: {
      handler(value) {
        this.isEdit = !_.isEmpty(value);
        // this.LoadData(value)
      },
      immediate: true
    },
    isRefresh: {
      handler(value) {
        this.onResetPage();
        this.LoadData(this.RPGUID);
      },
      immediate: true
    }
  },
  methods: {
    onClear() {
      this.onResetPage();
      this.$emit("close");
    },
    LoadData(guid) {
      if (guid !== "") {
        this.$post(CONFIG.advancePayGetURL, {RPGUID: guid}, datas => {
          this.advancePay = datas;
          this.setBankAccountBalance();
        });
      }
    },
    onResetPage() {
      // 重置页面
      if (!this.isEdit) {
        this.advancePay.RPGUID = "";
        this.advancePay.BillType = "";
        this.advancePay.CCompanyID = "";
        this.advancePay.CompanyName = "";
        this.advancePay.Expenditure = ""; // 金额，支出
      }
      if (!this.isSaveAndContinue) {
        this.advancePay.BankAccountGUID = "";
      }
      this.advancePay.BusinessDate = this.getNowFormatDate();
      this.advancePay.Expenditure = ""; // 金额，支出
      this.advancePay.Abstract = ""; // 备注
    },
    onSave() {
      // 保存
      if (this.CheckData() === false) {
        return false;
      }
      this.dialogLoading = true;
      this.LoadingText = "正在保存，请稍等...";
      this.$post(
        this.isEdit ? CONFIG.advancePayEditURL : CONFIG.advancePayAddURL,
        this.advancePay,
        (datas, logic) => {
          this.dialogLoading = false;
          if (logic.code !== 200) {
            this.$message({message: logic.msg, type: "error"});
          } else {
            this.$message({message: "保存成功", type: "success"});
            if (!this.isEdit) {
              this.onResetPage();
            }
            if (!this.isSaveAndContinue || this.isEdit) {
              this.$emit("confirm", true);
            }
          }
        }
      );
    },
    CheckData() {
      // 检查数据的正确性
      let ret = true;
      if (this.advancePay.BankAccountGUID === "") {
        ret = false;
        this.$message({message: "请选择账户", type: "error"});
      } else if (
        this.advancePay.BusinessDate === "" ||
        this.advancePay.BusinessDate === null ||
        this.advancePay.BusinessDate === undefined
      ) {
        ret = false;
        this.$message({message: "请输入付款时间", type: "error"});
      } else if (this.advancePay.CCompanyID === "") {
        ret = false;
        this.$message({message: "请选择供应商", type: "error"});
      } else if (this.advancePay.Expenditure === "") {
        ret = false;
        this.$message({message: "请输入金额", type: "error"});
      } else if (!isFinite(this.advancePay.Expenditure)) {
        ret = false;
        this.$message({message: "金额必须为数字或者小数", type: "error"});
      }
      return ret;
    },
    SearchCompany() {
      if (!this.isEdit) {
        this.InTypes = "2,3"; // 客户和供应商客户
        this.dialogCompanySelectVisible = true;
      }
    },
    companyChecked(item) {
      if (this.hasValue(item)) {
        if (this.hasValue(item.CCompanyID)) {
          this.advancePay.CCompanyID = item.CCompanyID;
        }
        if (this.hasValue(item.CompanyName)) {
          this.advancePay.CompanyName = item.CompanyName;
        }
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },

    // 获取当前时间，格式YYYY-MM-DD
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

    // 获取银行账户
    async getBankAccounts() {
      this.bankAccounts = await this.$post(CONFIG.getBankAccountsURL);
      this.setBankAccountBalance();
    },
    // 设置银行账户余额
    setBankAccountBalance() {
      if (
        this.advancePay.BankAccountGUID &&
        this.advancePay.BankAccountGUID !== ""
      ) {
        let item = _.find(
          this.bankAccounts,
          m => m.CBGUID === this.advancePay.BankAccountGUID
        );
        item && (this.advancePay.Balance = item.Amount);
      }
    }
  },
  async created() {
    this.advancePay = defaultAdvancePay;
    this.advancePay.BusinessDate = this.getNowFormatDate();
    await this.getBankAccounts();
  }
};
</script>

<style lang="scss">
.dialog-finpayin-add-box {
  .form_Row_W100 {
    padding: 5px 0;
  }
}
</style>

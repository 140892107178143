<template>
  <div class="module-wrapper m-fin-capital-flow-1">
    <!-- :on-page-changed="onBindData" -->
    <zg-table
      ref="table"
      :width="zgTableWidth"
      :columns="columns"
      :item-source="itemSource"
      :page-size="pageSize"
      :page-index="pageIndex"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :on-page-changed="onBindData"
      @inCome-click="onShowDetail"
      @expenditure-click="onShowDetail"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FinCapitalFlow')"
      box-class="m-fin-capital-flow-1"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-row-bodymenus-handle="onRowBodymenusHandle"
      :options="tableOptions"
    >
      <div slot="header" class="v-table-toolbar">
        <el-row>
          <znl-input
            layout="left"
            form-type="select"
            title="账户："
            title-width="40px"
            :disabled="false"
            width="260px"
            inp-perc="100%"
            @change="selectBankName"
            :select-options="selectOptionsBankAccountAndName"
            placeholder="-请选择现金银行账户-"
            popper-class="popperClass"
            :border="true"
            v-model="filterFields.BankAccountGUID"
            :clearable="true"
          ></znl-input>
          <znl-input
            size="mini"
            layout="left"
            width="260px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.CompanyName"
            title="公司名称："
            title-width="70px"
            placeholder="请输入公司名称"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            placeholder=" - -"
            value-format="yyyy-MM-dd"
            title="记账日期从"
            title-width="70px"
            size="mini"
            layout="left"
            width="180px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.startDate"
          ></znl-input>
          <span>到</span>
          <znl-input
            form-type="datepicker"
            placeholder=" - - "
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="110px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.endDate"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="selectOptionsBillType"
            placeholder="-请选择收支流水类型-"
            size="mini"
            layout="left"
            width="200px"
            title="收支类型"
            title-width="60px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.BillType"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-input
            size="mini"
            layout="left"
            width="260px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.Abstract"
            title="摘要："
            title-width="40px"
            placeholder="请输入摘要"
          ></znl-input>

          <znl-input
            size="mini"
            layout="left"
            width="180px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.MinAmount"
            title="流水金额："
            title-width="70px"
            placeholder="请输入最小金额"
            @input="VerificationValue"
          ></znl-input>
          <span>到</span>
          <znl-input
            size="mini"
            layout="left"
            width="110px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.MaxAmount"
            placeholder="请输入最大金额"
            @input="VerificationValue"
          ></znl-input>

          <znl-button
            type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn ml5"
          >
            <znl-button-menu @click="SearchGridList()">
              <i class="iconfont icon-search_ic"></i>
              <span>查询</span>
            </znl-button-menu>
          </znl-button>
          <!-- 新增 -->
          <znl-button
            style-type="mac"
            :height="22"
            @click="finCapitalAdd()"
            v-if="this.hasRes('Add')"
          >
            <span> <i class="iconfont icon-add_btn_ic"></i>新增流水 </span>
          </znl-button>
          <znl-button
            style-type="mac"
            :btns="printznlbtns"
            :height="22"
            v-if="this.hasRes('Export')"
            @click="onExport"
            tip="按搜索条件"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>

          <div class="header-tips">
            可添加非业务相关的资金流水，如
            <span>“期初余额”</span>
          </div>
        </el-row>
      </div>

      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24" class="money-summary">
            <span>
              期初余额 :
              <span class="number">{{ summary.BeginBalance }}</span>
            </span>
            <span>
              总收入：
              <span class="number">{{ summary.InCome }}</span>
            </span>
            <span>
              总支出：
              <span class="number">{{ summary.Outgoings }}</span>
            </span>
            <span>
              期末余额：
              <span class="number">{{ summary.EndBalance }}</span>
            </span>

            <!-- <span v-for="(item, key) in sumList" :key="key">
              {{item.Name}} :
              余额：{{ item.Balance }}
              收入：{{ item.InCome }}
              支出：{{ item.Outgoings }}&nbsp;&nbsp;
            </span>-->
          </el-col>
        </el-row>
      </div>
    </zg-table>

    <FinCapitalFlowDetails
      ref="FinCapitalFlowDetails"
      v-if="dialogDetailVisible"
      :visible="dialogDetailVisible"
      :hasSeeDelete="hasRes('Delete')"
      :isRefresh="refreshFlowDetails"
      height="50%"
      :RPGUID="RPGUID"
      @confirm="
        () => {
          onBindData(1), (dialogDetailVisible = false);
        }
      "
      @close="
        () => {
          dialogDetailVisible = false;
        }
      "
    ></FinCapitalFlowDetails>

    <fin-capital-add
      ref="FinCapitalAdd"
      :RPGUID="RPGUID"
      v-if="finCapitalAddVisible"
      :visible="finCapitalAddVisible"
      :title="finCapitalAddTitle"
      :isRefresh="isRefreshAddPage"
      @close="
        () => {
          finCapitalAddVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (finCapitalAddVisible = false), this.onBindData(1);
        }
      "
    ></fin-capital-add>

    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="
        () => {
          setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          setExportColumnVisible = false;
        }
      "
    ></export-column>
  </div>
</template>

<script>
import FinCapitalFlowDetails from "@/components/modules/Fin/FinCapitalFlowDetails";
import finCapitalAdd from "@c_modules/Fin/FinCapitalAdd";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  PK: "RPGUID",
  configURL: "FinCapitalFlow/GetConfig",
  saveConfigURL: "FinCapitalFlow/SaveConfig",
  searchURL: "FinCapitalFlow/Search",
  addURL: "FinCapitalFlow/Add",
  updateURL: "FinCapitalFlow/Edit",
  deleteURL: "FinCapitalFlow/Del",
  multiDeleteURL: "FinCapitalFlow/MultiDel",
  getFlowSummaryURL: "FinCapitalFlow/GetFlowSummary",
};

export default {
  name: "FinCapitalFlow",
  mixins: [exportData, getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    FinCapitalFlowDetails,
    finCapitalAdd,
    ExportColumn,
  },
  props: {
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    refreshBank: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      finCapitalAddTitle: "新增资金流水",
      dialogCompanySelectVisible: false,
      columns: [],
      defaultConfig: {},
      itemSource: [],
      searchFields: {
        BankAccountName: "",
      },
      filterFields: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
        endDate: "",
        BankAccountGUID: "",
        CompanyName: "",
        BillType: null,
        Abstract: "",
        MinAmount: null,
        MaxAmount: null,
      },
      selectOptionsBillType: [
        { key: 1, value: "销售收入" },
        { key: 2, value: "采购支出" },
        { key: 3, value: "其它收入" },
        { key: 4, value: "其它支出" },
        { key: 5, value: "期初余额" },
        { key: 6, value: "预收款" },
        { key: 7, value: "预付款" },
      ],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 1,
      dialogDetailVisible: false,
      RPGUID: "",
      isRefreshAddPage: false,
      resourceList: [],
      finCapitalAddVisible: false,
      refreshFlowDetails: false,
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          },
        },
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: "",
      searchCondition: {},
      loading: false,
      summary: {
        BeginBalance: 0,
        EndBalance: 0,
        InCome: 0,
        Outgoings: 0,
      },
      currencyList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      tableOptions: {
        // 表格配置项
        showTitleMenus: true, // 是否显示表头右键菜单
        slideMultipleChoiceOpen: true, // 开启滑动框选单元格
        totalField: ["InCome", "Expenditure"], // 合计行
        showIndex: true,
        baseSortOpen: true, // 开启组件内的排序算法
      },
      zgTableWidth: 0,
    };
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage("finCapitalFlow", CONFIG.configURL);
      }

      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      let tempConfig = [];
      if (this.hasRes("Delete")) {
        tempConfig.push({
          width: 50,
          type: "button",
          title: "删除",
          btnTxt: "删除",
          visible: true,
          isDeal: false,
          showButton: (d) => {
            let isShow = false;
            switch (d.BillTypeStr) {
              case "预付款":
              case "其它支出":
              case "其它收入":
              case "销售收入":
              case "采购支出":
              case "期初余额":
                isShow = true;
                break;
            }

            return isShow ? "删除" : "";
          },
          click: (row) => {
            if (
              row.BillTypeStr === "销售收入" ||
              row.BillTypeStr === "采购支出" ||
              row.BillTypeStr === "期初余额"
            ) {
              this.onDeleteSalesPurchase(row);
            } else {
              this.onDelete([row]);
            }
          },
        });
      }
      tempConfig.push({
        width: 50,
        type: "button",
        title: "编辑",
        btnTxt: "编辑",
        visible: true,
        isDeal: false,
        showButton: (d) => {
          let isShow = false;
          switch (d.BillTypeStr) {
            case "预付款":
            case "其它支出":
            case "其它收入":
            case "销售收入":
            case "采购支出":
            case "期初余额":
              isShow = true;
              break;
          }
          return isShow ? "编辑" : "";
        },
        click: (row) => {
          if (
            row.BillTypeStr === "销售收入" ||
            row.BillTypeStr === "采购支出"
          ) {
            this.onSalesPurchaseEdit(row);
          } else {
            this.finCapitalEdit(row);
          }
        },
      });

      let columns = config.ColumnConfigs;
      columns = tempConfig.concat(columns);

      // 表格值的点击 start
      _.extend(
        columns[_.findIndex(columns, (item) => item.BindField === "InCome")],
        {
          event: "inCome-click",
          className: "v-cell-link", // (r) => { return r.billTypeStr === '销售收入' || r.billTypeStr === '采购支出' ? 'v-cell-link' : '' }
        }
      );

      _.extend(
        columns[
          _.findIndex(columns, (item) => item.BindField === "Expenditure")
        ],
        {
          event: "expenditure-click",
          className: "v-cell-link", // (r) => { return r.billTypeStr === '销售收入' || r.billTypeStr === '采购支出' ?  : '' }
        }
      );

      _.extend(
        columns[
          _.findIndex(columns, (item) => item.BindField === "CurrencyCode")
        ],
        {
          type: "select",
          width: 74,
          options: this.currencyList,
        }
      );

      columns = this.handleZgColumns(columns);

      // 表格值的点击 end
      this.columns = columns;
      this.defaultConfig = config;
      this.parameterConfig = config.ParamJosn;

      this.$nextTick(() => {
        this.zgTableWidth = this.getZgTableWidth(columns);
        this.$refs.table.init();
        this.$emit("page-loading", false);
        this.loading = false;
        this.gridShow = true;
      });
    },

    /* 处理zg-table columns */
    handleZgColumns(list) {
      list.forEach((item) => {
        item.showOrderby = true;
      });
      return list;
    },

    getZgTableWidth(columns) {
      let width = 0;
      let dom = document.querySelector(".m-fin-capital-flow-1");
      if (dom) {
        let domData = dom.getBoundingClientRect();
        if (domData.width) return domData.width;
      }
      columns.forEach((item) => {
        width += item.ColumnWidth;
      });
      width += 4;
      return width;
    },

    /* 单元格右键菜单的触发事件 */
    onRowBodymenusHandle(opts) {
      let { handleType } = opts;
      switch (handleType) {
        case "resetConfig":
          this.onResetTableConfigClick();
          break;
      }
    },
    SearchGridList() {
      if (!this.hasValue(this.filterFields.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        return false;
      }
      if (
        this.DateDiffer(this.filterFields.startDate, this.filterFields.endDate)
      ) {
        this.$message({
          message:
            "为避免加载过慢,系统已限制一次只能查询时间段为一年的流水数据!",
          type: "error",
        });
      } else {
        this.onBindData(1);
      }
    },
    onSearch(params = null) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      this.zgTableWidth = this.getZgTableWidth();
      this.loading = true;
      let vm = this;
      let filterFields = {};
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] =
            value;
        });
      }
      this.searchCondition = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          searchType: 1,
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        filterFields
      );

      await this.$post(
        CONFIG.searchURL,
        this.searchCondition,
        (datas, logic) => {
          if (logic.code === 200) {
            this.pageIndex = datas.PageIndex;
            this.itemSource = datas.ResultList;
            this.totalCount = datas.TotalCount;
            vm.getTotal();
          }
        }
      ).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
        this.$emit("page-loading", false);
      });
    },
    selectBankName(obj) {
      if (this.hasValue(obj)) {
        this.searchFields.BankAccountGUID = obj.key;
      } else {
        this.searchFields.BankAccountGUID = null;
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    finCapitalAdd() {
      this.RPGUID = "";
      this.isRefreshAddPage = !this.isRefreshAddPage;
      this.finCapitalAddTitle = "新增收支流水";
      this.finCapitalAddVisible = true;
    },
    finCapitalEdit(row) {
      this.finCapitalAddTitle = "编辑收支流水";
      let rows = [row];
      if (rows.length !== 1) {
        return this.$message({
          message: "请选择一条数据进行操作",
          type: "error",
        });
      } else {
        this.RPGUID = rows[0].RPGUID;
        this.finCapitalAddVisible = true; // 弹窗是否出现
      }
    },

    // 删除
    onDelete(rows) {
      let vm = this;
      if (_.isUndefined(rows[0])) {
        return this.$message({
          message: "请至少选择一条数据进行操作",
          type: "error",
        });
      }
      let amount = rows[0].InCome || rows[0].Expenditure || 0;
      let bankAcct = `${rows[0].BankAccountName}(${rows[0].BankAccountNo})`;
      let cfmMsg = `删除将会修改相关银行账号的余额。<br>确认删除`;
      if (this.hasValue(rows[0].CompanyName)) {
        cfmMsg += `公司为【${rows[0].CompanyName}】 `;
      }
      cfmMsg += `金额为【${amount}】的资金流水？`;

      this.$confirm(cfmMsg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        let param = _.filter(rows, (row) => row[CONFIG.PK]);
        return this.$post(CONFIG.multiDeleteURL, param, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "删除成功", type: "success" });
            vm.onBindData(1);
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        });
      });
    },

    // 删除销售收入，采购支出的流水
    async onDeleteSalesPurchase(row) {
      let amount = row.InCome || row.Expenditure || 0;
      let vm = this;
      let cfmMsg = "";
      let bankAcct = `${row.BankAccountName}(${row.BankAccountNo})`;
      if (row.BillTypeStr === "采购支出") {
        cfmMsg = `删除将会增加相关银行账号的余额，并且修改相关单据的已付金额。<br>确认删除`;
      } else if (row.BillTypeStr === "销售收入") {
        cfmMsg = `删除将会减少相关银行账号的余额，并且修改相关单据的已收金额。<br>确认删除`;
      } else {
        cfmMsg = `删除将会${
          row.InCome ? "减少" : "增加"
        }相关银行账号的余额。<br>确认删除`;
      }
      if (this.hasValue(row.CompanyName)) {
        cfmMsg += `公司为【${row.CompanyName}】 `;
      }
      cfmMsg += `金额为【${amount}】的资金流水？`;
      let isok = await this.$confirm(cfmMsg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
        customClass: "confirm-box-500",
      });
      if (isok) {
        this.$post(CONFIG.deleteURL, row, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "删除成功", type: "success" });
            vm.onBindData(1);
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        });
      }
    },

    async onExport() {
      if (!this.hasValue(this.filterFields.startDate)) {
        this.$message({ message: "请选择开始日期!", type: "error" });
        return false;
      }
      if (
        this.DateDiffer(this.filterFields.startDate, this.filterFields.endDate)
      ) {
        this.$message({
          message: "为避免加载过慢,系统已限制一次只能导出时间段为一年的数据!",
          type: "error",
        });
        return false;
      }
      this.loading = true;
      this.loading = await this.exportData(
        "FinCapitalFlow",
        this.searchCondition,
        this.columns,
        "资金流水"
      );
    },

    showExportColumn() {
      this.addColumnsList = this.columns;
      this.setConfigColumns = this.defaultConfig;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.setExportColumnVisible = true;
    },
    selectBillType(obj) {
      if (this.hasValue(obj)) {
        this.searchFields.BillType = obj.key;
        this.onBindData(1);
      } else {
        this.searchFields.BillType = 0;
        this.onBindData(1);
      }
    },

    onShowDetail(e) {
      if (e.field !== "InCome" && e.field !== "Expenditure") {
        return;
      }
      if (e.row.BillTypeStr === "销售收入" && this.hasValue(e.row.InCome)) {
        this.dialogDetailVisible = true;
        this.RPGUID = e.row.RPGUID;
        this.refreshFlowDetails = !this.refreshFlowDetails;
      }
      if (
        e.row.BillTypeStr === "采购支出" &&
        this.hasValue(e.row.Expenditure)
      ) {
        this.dialogDetailVisible = true;
        this.RPGUID = e.row.RPGUID;
        this.refreshFlowDetails = !this.refreshFlowDetails;
      }
    },

    // 销售收入、采购支出数据点击编辑时
    onSalesPurchaseEdit(row) {
      if (this.hasValue(row.InCome) || this.hasValue(row.Expenditure)) {
        this.dialogDetailVisible = true;
        this.RPGUID = row.RPGUID;
        this.refreshFlowDetails = !this.refreshFlowDetails;
      }
    },

    // 获取合计数据
    getTotal() {
      if (this.pageIndex === 1) {
        this.$post(CONFIG.getFlowSummaryURL, this.searchCondition, (data) => {
          this.summary = data;
        });
      }
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FinCapitalFlow", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("FinCapitalFlow", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("FinCapitalFlow", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FinCapitalFlow");
    },
    VerificationValue(value) {
      if (isNaN(value)) {
        return this.$message({
          message: "请输入正确的金额",
          type: "error",
        });
      }
    },
  },
  async mounted() {
    await this.onInit();
  },
  async created() {
    // 币种
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );
  },
  watch: {
    async refreshBank() {},
  },
};
</script>

<style lang="scss">
.m-fin-capital-flow-1 {
  width: 100%;
  .znl-input-title {
    padding-left: 5px;
  }
  .ml5 {
    margin-left: 5px;
  }
  .header-tips {
    display: inline;
    color: #a0a0a0;
    span {
      color: #2f3699;
    }
  }
  .money-summary {
    span {
      margin-right: 12px;
      .number {
        color: rgb(255, 0, 0);
      }
    }
  }
}
.confirm-box-500 {
  width: 500px;
}
</style>

<template>
<!-- 子公司 - 发票抬头选择 -->
  <znl-dialog
    :title="title"
    height="500px"
    width="790px"
    :visible="visible"
    :loading="loadingState"
    :loading-text="loadingText"
    :append-to-body="true"
    :confirm-call-back="confirmCallBack"
    :close-on-click-modal='false'
    @close="() => {$emit('close')}">
    <znl-gridmodule
      ref="flexGrid"
      :action-btns="actionBtns"
      :has-znl-btns="false"
      :columns="columns"
      height="100%"
      layoutType="other"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :search-fields="fieldsFilter"
      :role="role"
      :users="$store.state.users"
      :on-init="onInit"
      :on-search="onSearch"
      :is-action-from="false"
      :is-btn="false"
      :loading="loadingState"
      @ondblclick="(item) => {$emit('confirm', item)}"
      checkboxBindingKey="SubCompanyID">
    </znl-gridmodule>
  </znl-dialog>
</template>

<script>
const Config = {
  PK: 'SubCompanyID',
  configURL: 'InvoiceTitleInfo/GetConfig',
  searchURL: 'InvoiceTitleInfo/SearchSubCompany',
  default: null
}
export default {
  mixins: [],
  config: Config,
  name: 'InvoiceTitleSelect',
  components: { },
  data () {
    return {
      actionBtns: [],
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      columnColors: [],
      resourceList: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loadingState: false,
      loadingText: '加载中，请稍后...'
    }
  },
  computed: {
    rightMenus () {
      return []
    }
  },
  props: {
    visible: false,
    title: {
      type: String,
      default: '选择'
    },
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('InvoiceTitleSelect', Config.configURL)
      }
      var tempColumns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.resourceList = config.ResourceList.map(item => item.Code)
      // 行业
      if (_.find(tempColumns, function (column) { return column.binding === 'Gender' })) {
        var genderDataMap = []
        genderDataMap.push({'key': '0', 'value': '女'})
        genderDataMap.push({'key': '1', 'value': '男'})
        _.extend(_.find(tempColumns, function (column) { return column.binding === 'Gender' }), {dataMap: genderDataMap, showDropDown: true})
      }
      // 数据来源
      if (_.find(tempColumns, function (column) { return column.binding === 'DataSource' })) {
        var dataSourceDataMap = []
        _.each(this.dataMapSource.DataSource, function (item) {
          dataSourceDataMap.push({'key': item, 'value': item})
        })
        _.extend(_.find(tempColumns, function (column) { return column.binding === 'DataSource' }), {dataMap: dataSourceDataMap, showDropDown: true})
      }
      this.defaultConfig = config
      this.columns = tempColumns
      this.pageSize = config.PageSize
      this.role = config.Role

      if (this.initData) {
        this.$nextTick(() => {
          this.onBindData()
        })
      }
    },

    onBindData () {
      let fieldsData = _.extend({}, this.fieldsFilter)
      var jsonData = _.extend({}, {
        FieldWhereString: JSON.stringify(fieldsData)
      })
      return this.$post(Config.searchURL, jsonData, (data) => {
        this.itemSource = data
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },

    confirmCallBack () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows && rows.length > 0) {
        this.$nextTick(() => {
          this.$emit('confirm', rows[0])
        })
      } else {
        this.$message({message: '请先选择一项', type: 'error'})
        return false
      }
    }
  }
}
</script>

<style lang="scss">

</style>

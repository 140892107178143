<template>
  <div>
    <znl-dialog
      :title="title"
      :visible="visible"
      v-loading="dialogLoading"
      :element-loading-text="LoadingText"
      height="370px"
      width="600px"
      class="dialog-finrecin-add-box"
      :close-on-click-modal="false"
      @close="()=>{$emit('close')}"
    >
      <div slot="znl-dialog" class="row-box">
        <el-row>
          <!--第一列 -->
          <el-col :span="24">
            <el-row class="form_Row_W100">
              <znl-input
                form-type="select"
                :disabled="isEdit"
                placeholder="请选择"
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="账户："
                :clearable="true"
                :border="true"
                width="46%"
                title-width="70px"
                :select-options="selectOptionsBankAccountAndName"
                v-model="advancePay.BankAccountGUID"
                type="text"
              >
                <!--   -->
              </znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="datepicker"
                :disabled="isEdit"
                placeholder="收款时间"
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="收款时间："
                :clearable="true"
                :border="true"
                width="46%"
                title-width="70px"
                v-model="advancePay.BusinessDate"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                :disabled="false"
                placeholder="公司名称"
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="公司名称："
                width="46%"
                title-width="70px"
                :clearable="true"
                :border="true"
                :on-icon-click="SearchCompany"
                v-model="advancePay.CompanyName"
                type="text"
              >
                <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
              </znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                :disabled="false"
                placeholder="预收金额"
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="预收金额："
                :clearable="true"
                :border="true"
                width="46%"
                title-width="70px"
                v-model="advancePay.InCome"
                type="text"
              ></znl-input>
            </el-row>
            <el-row class="form_Row_W100">
              <znl-input
                form-type="input"
                :disabled="false"
                placeholder="备注"
                size="mini"
                layout="left"
                title="备注："
                :clearable="true"
                :border="true"
                width="100%"
                title-width="70px"
                :rows="5"
                v-model="advancePay.Abstract"
                type="textarea"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row align="middle" type="flex" justify="end" slot="footer" class="dialog-footer">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-checkbox v-show="!isEdit" v-model="isSaveAndContinue" style="margin-right:5px"></el-checkbox>
          <label for="continueSave" style="margin-right:10px;" v-show="!isEdit">保存后继续操作</label>
          <znl-button :height="30" :width="60" @click="onClear" style="margin-right:10px">关闭</znl-button>
          <znl-button
            @click="onSave"
            :height="30"
            :width="60"
            style-type="main"
            :disabled="dialogLoading"
          >保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      :companyType="InTypes"
      title="选择公司名称"
      @close="()=>{dialogCompanySelectVisible=false}"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
      @confirm="companyChecked"
    ></d-company-contact-select>
  </div>
</template>
<script>
import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  advancePayAddURL: "FinRecInAdvance/Add",
  advancePayEditURL: "FinRecInAdvance/Edit",
  advancePayGetURL: "FinRecInAdvance/Get"
};

const defaultAdvancePay = {
  RPGUID: "",
  BillType: "",
  CCompanyID: "",
  CompanyName: "",
  InCome: "", // 金额，收入
  BankAccountNo: "", // 账户号码
  BankAccountName: "", // 账户名称
  BankAccountGUID: "",
  BusinessDate: null, // 付款时间
  CreatedByName: "", // 录入人
  CreatedGUID: "",
  Balance: 0,
  Abstract: "" // 备注
};
export default {
  name: "FinPayinAdd",
  mixins: [getCommonDataMixin],
  components: {
    dCompanyContactSelect
  },
  data() {
    return {
      isEdit: false,
      advancePay: {
        // 预付款对象
        RPGUID: "",
        BillType: "",
        CCompanyID: "",
        CompanyName: "",
        InCome: "", // 金额，收入
        BankAccountNo: "", // 账户号码
        BankAccountName: "", // 账户名称
        BankAccountGUID: "",
        BusinessDate: null, // 付款时间
        CreatedByName: "", // 录入人
        CreatedGUID: "",
        Balance: 0,
        Abstract: "" // 备注
      },
      isSaveAndContinue: false,
      InTypes: "", // 1:客户，2：供应商，3:两者都是的公司
      dialogCompanySelectVisible: false,
      dialogLoading: false,
      LoadingText: ""
    };
  },
  props: {
    RPGUID: String, // 库存GUID
    isRefresh: Boolean, // 是否刷新
    title: String,
    visible: Boolean
  },
  watch: {
    RPGUID: {
      handler(value) {
        this.isEdit = !_.isEmpty(value);
        // this.LoadData(value)
      },
      immediate: true
    },
    isRefresh: {
      handler(value) {
        this.onResetPage();
        this.LoadData(this.RPGUID);
      },
      immediate: true
    }
  },
  methods: {
    onClear() {
      this.onResetPage();
      this.$emit("close");
    },
    LoadData(guid) {
      // 加载账户
      if (guid !== "") {
        this.$post(CONFIG.advancePayGetURL, { RPGUID: guid }, datas => {
          this.advancePay = datas;
        });
      }
    },
    onResetPage() {
      // 重置页面
      if (!this.isEdit) {
        this.advancePay.RPGUID = "";
        this.advancePay.BillType = "";
        this.advancePay.CCompanyID = "";
        this.advancePay.CompanyName = "";
      }
      if (!this.isSaveAndContinue) {
        this.advancePay.BankAccountGUID = "";
      }
      this.advancePay.BusinessDate = this.getNowFormatDate(); // 付款时间
      this.advancePay.InCome = ""; // 金额，收入
      this.advancePay.Abstract = ""; // 备注
    },
    onSave() {
      // 保存
      if (this.CheckData()) {
        this.dialogLoading = true;
        this.LoadingText = "正在保存，请稍等...";
        this.$post(
          this.isEdit ? CONFIG.advancePayEditURL : CONFIG.advancePayAddURL,
          this.advancePay,
          datas => {
            this.dialogLoading = false;
            if (datas.RPGUID !== "") {
              this.$message({ message: "保存成功", type: "success" });
              if (!this.isEdit) {
                this.onResetPage();
              }
              if (!this.isSaveAndContinue || this.isEdit) {
                this.$emit("confirm", true);
              }
            }
          }
        );
      }
    },
    CheckData() {
      // 检查数据的正确性
      let ret = true;
      if (this.advancePay.BankAccountGUID === "") {
        ret = false;
        this.$message({ message: "请选择账户", type: "error" });
      } else if (
        this.advancePay.BusinessDate === "" ||
        this.advancePay.BusinessDate === null ||
        this.advancePay.BusinessDate === undefined
      ) {
        ret = false;
        this.$message({ message: "请输入收款时间", type: "error" });
      } else if (this.advancePay.CCompanyID === "") {
        ret = false;
        this.$message({ message: "请选择公司名称", type: "error" });
      } else if (this.advancePay.InCome === "") {
        ret = false;
        this.$message({ message: "请输入金额", type: "error" });
      } else if (!isFinite(this.advancePay.InCome)) {
        ret = false;
        this.$message({ message: "金额必须为数字或者小数", type: "error" });
      }
      return ret;
    },
    SearchCompany() {
      if (!this.isEdit) {
        this.InTypes = "1,3"; // 客户和供应商客户
        this.dialogCompanySelectVisible = true;
      }
    },
    companyChecked(item) {
      if (this.hasValue(item)) {
        if (this.hasValue(item.CCompanyID)) {
          this.advancePay.CCompanyID = item.CCompanyID;
        }
        if (this.hasValue(item.CompanyName)) {
          this.advancePay.CompanyName = item.CompanyName;
        }
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },

    // 获取当前时间，格式YYYY-MM-DD
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    }
  },
  created() {
    this.advancePay = defaultAdvancePay;
    this.advancePay.BusinessDate = this.getNowFormatDate(); // 付款时间
  }
};
</script>

<style lang="scss" scoped>
.dialog-finrecin-add-box {
  .form_Row_W100 {
    padding: 5px 0;
  }
}
</style>

<template>
  <!-- 发票清单 -->
  <znl-gridmodule
    ref="flexGrid"
    height="323px"
    gridtype="action"
    layout-type="other"
    :has-znl-btns="true"
    :action-btns="actionBtns"
    element-loading-text="正在保存,请稍等..."
    :is-multi-rows-check-fixed="false"
    :columns="columns"
    :item-source="itemSource"
    :edit-row-index="editRowIndex"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :on-init="onInit"
    :users="$store.state.users"
    :loading="loading"
    :is-show-rightbtns="false"
    :show-add-row-right-menu="false"
    :show-delete-row-right-menu="false"
    @edit:cell-ended="onCellEditEnd"
    @page-loading="d=>{$emit('page-loading', d)}"
  >
  </znl-gridmodule>
</template>
<script>
const CONFIG = {
  configURL: 'PurchaseInvoiceLine/GetConfig'
}

export default {
  name: 'SalesInvoiceEditLine',
  data () {
    return {
      columns: [],
      itemSource: [],
      columnColors: [],
      rowColors: [],
      editRowIndex: -1,
      loading: false,
      newRowIndex: 0,
      actionBtns: []
    }
  },
  props: {
    isRedRush: {
      type: Boolean,
      default: () => { return false }
    }
  },
  methods: {
    async onInit () {
      let config = await this.isSaveStorage('PurchaseInvoiceLine', CONFIG.configURL)
      // 获取主单参数配置
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      this.pageSize = config.PageSize
      // 判断发票编辑入口
      if (this.$store.state.paramOrder) {
        let paramOrder = this.$store.state.paramOrder
        this.openFrom = paramOrder.from

        // 从销售发票，点击新增按钮
        if (this.openFrom === 'add') {

        }

        this.$store.commit('setParamOrder', {})
      }
      this.initBtns()
      this.appendOperatCol(columns)
      this.columns = columns
      this.$nextTick(() => {
        this.dialogLoading = false
      })
    },

    initBtns () {
      this.actionBtns = [
        {
          name: '新增',
          // isShow: this.hasRes('Add'),
          iconName: 'icon-add_btn_ic',
          click: () => { this.addEmptyRow() }
        }
      ]
    },

    appendOperatCol (columns) {
      let btns = [
        {
          content: '移除',
          className: 'znl-table-btn-success',
          click: (row) => {
            this.onRemoveRow(row)
          }
        }

      ]
      // if (!this.isRedRush) {
      //   btns.push({
      //     content: '选择进项',
      //     className: 'znl-table-btn-success',
      //     click: (row) => {
      //       this.onSelectEntry(row)
      //     }
      //   })
      // }
      columns.push(
        {
          binding: 'operation',
          name: 'operation',
          header: '操作',
          visible: true,
          dataType: 1,
          width: 120,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: true,

          buttons: () => {
            return btns
          }
        },
      )
    },

    addEmptyRow () {
      this.newRowIndex = this.newRowIndex - 1
      this.itemSource.push({
        SILineID: this.newRowIndex,
        SILineGUID: null,
        ProductCategory: null,
        Model: null,
        Unit: null,
        Qty: null,
        Price: null,
        Amount: null,
        TaxRate: null,
        TaxAmount: null,
        SourceBusinessDate: null,
        TicketedAmount: null,
        TicketAmount: null,
        Brand: null,
        Packaging: null,
        MakeYear: null,
        PriceInTax: null
      })
    },

    // 添加到发票清单 (入库单添加)
    addLine (row) {
      if (_.some(this.itemSource, r => r.StkInLineGUID === row.StkInLineGUID)) {
        this.$message({message: '请不要重复添加', type: 'warning'})
        return false
      }

      this.newRowIndex = this.newRowIndex - 1

      let item = _.extend({}, row, {
        SILineID: this.newRowIndex,
        SILineGUID: null
      })

      this.itemSource.push(item)
      this.calcAmount(item)
      this.calcTaxAmt(item)
      this.$message({message: '已添加到入库清单', type: 'success'})
    },

    // 移除一行
    onRemoveRow (row) {
      _.each(this.itemSource, (item, index) => {
        if (_.isEqual(item, row)) {
          this.itemSource.splice(index, 1)
        }
      })
      this.calcTotalAmount()
    },

    // 选择进项
    onSelectEntry (row) {},

    onCellEditEnd (afterValue, beforeValue, colName, gridObj, rowIndex, colIndex) {
      let row = gridObj.row
      switch (colName) {
        case 'Qty':
          this.$emit("on-line-qty-changed", afterValue, beforeValue, row.StkInLineGuid);
          if (!_.isUndefined(row.Price)) {
            this.calcAmount(row)
          } else {
            this.calcPrice(row)
          }
          this.calcTaxAmt(row)
          break
        case 'Price':
          row.Price = this.toFixed(row.Price, 11)
          this.calcAmount(row)
          this.calcTaxAmt(row)
          break
        case 'Amount':
          this.calcPrice(row)
          this.calcTaxAmt(row)
          break
        case 'TaxRate':
          this.calcTaxAmt(row)
          break
        // case 'Qty':
        //   break
        // case 'Qty':
        //   break
        // case 'Qty':
        //   break
        // case 'Qty':
        //   break
        // case 'Qty':
        //   break
        // case 'Qty':
        //   break
      }
    },

    calcPrice (row) {
      let amt = row.Amount || 0
      let qty = row.Qty || 0
      if (qty === 0) {
        row.Price = null
      } else {
        row.Price = this.getNumber(amt / qty, 11)
      }
      if (isNaN(row.Price)) {
        row.Price = null
      }
    },

    calcAmount (row) {
      let price = row.Price || 0
      let qty = row.Qty || 0
      let amt = price * qty
      if (isNaN(amt)) {
        amt = null
      } else {
        amt = this.toFixed(amt, 2)
      }
      row.Amount = amt
    },

    calcTaxAmt (row, calTotal = true) {
      if (row.TaxRate && row.Amount) {
        row.TaxAmount = parseFloat(row.Amount) * parseFloat(row.TaxRate) / 100
        if (isNaN(row.TaxAmount)) {
          row.TaxAmount = null
        } else {
          row.TaxAmount = row.TaxAmount.toFixed(2)
        }
      } else {
        row.TaxRate = null
      }
      if (calTotal) {
        this.calcTotalAmount()
      }
    },

    // 价税合计, 税额合计
    calcTotalAmount () {
      let sumTax = 0
      let sumAmt = 0
      _.each(this.itemSource, item => {
        let taxAmt = item.TaxAmount || 0
        sumTax += parseFloat(taxAmt)

        let amt = item.Amount || 0
        if (!isNaN(amt)) {
          sumAmt += parseFloat(amt)
        }
      })
      this.$emit('set-tax-amount', sumTax.toFixed(2))

      // 税价合计：发票清单金额+税额合计
      let total = parseFloat(sumAmt.toFixed(2)) + parseFloat(sumTax.toFixed(2))
      this.$emit('set-amount', total.toFixed(2))
    },

    getNumber (val, digit = 0) {
      if (isNaN(val)) {
        return null
      } else {
        return parseFloat(val.toFixed(digit))
      }
    },

    toFixed (val, digit = 0) {
      if (isNaN(val)) {
        return val
      } else {
        return parseFloat(parseFloat(val).toFixed(digit))
      }
    },

    onTaxRateChange (taxRate) {
      let vm = this
      _.each(this.itemSource, item => {
        item.TaxRate = taxRate
        vm.calcTaxAmt(item, false)
      })
      vm.calcTotalAmount( )
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>


<template>
    <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
        <FinCapitalFlow
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          v-if='hasRight("FinCapitalFlow")'
          @page-loading="(d)=>{pageLoading=d}"></FinCapitalFlow>
    </znl-layout-spa>
</template>

<script>
import FinCapitalFlow from '@/components/modules/Fin/FinCapitalFlow_v2'

export default {
  name: 'VFinCapitalFlow',
  components: {
    FinCapitalFlow
  },
  data () {
    return {
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VFinCapitalFlow'})
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

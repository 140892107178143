<template>
  <znl-dialog
    :visible="visible"
    width="760px"
    height="500px"
    class="znl_check_grid"
    title="已选择的型号列表"
    :loading="loading"
    :loading-text="elementLoadingText"
    :footer-height="0"
    @close="
      () => {
        $emit('close');
      }
    "
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="base"
      :columns="columns"
      height="100%"
      :grid-show="gridShow"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :is-multi-rows-check="false"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :users="$store.state.users"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-before-add-row="onBeforeAddRow"
      @page-loading="
        (d) => {
          $emit('page-loading', d);
        }
      "
      @selection-changed="
        (d) => {
          $emit('selection-changed', d);
        }
      "
    ></znl-gridmodule>
    <el-row slot="footer"></el-row>
  </znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";

const CONFIG = {};

export default {
  name: "WaitInvoiceCheckInfo",
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      gridShow: false,
      totalCount: 0,
      elementLoadingText: "数据加载中,请稍后...",
      loading: true,
    };
  },
  props: {
    visible: Boolean,
    showCustomerName: Boolean,
    showSupplierName: Boolean,
  },
  // watch: {

  // },
  methods: {
    onInit: async function () {
      let gridConfig = [
        {
          CTitle: "CheckGUID",
          BindField: "CheckGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 120,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: false,
        },
        {
          CTitle: "客户名称",
          BindField: "CustomerName",
          IsShow: this.showCustomerName,
          IsReadonly: true,
          ColumnWidth: 250,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "供应商名称",
          BindField: "SupplierName",
          IsShow: this.showSupplierName,
          IsReadonly: true,
          ColumnWidth: 250,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "型号",
          BindField: "Model",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 180,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "品牌",
          BindField: "Brand",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "税率",
          BindField: "TaxRate",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 70,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "数量",
          BindField: "Qty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
      ];
      let columns = this.flexGridColumnsHandler(gridConfig);
      columns.unshift({
        name: "delete",
        dataType: 5,
        binding: "delete",
        width: 45,
        header: "操作",
        visible: true,
        isReadOnly: true,
        buttons: [
          {
            content: "移除",
            className: "znl-table-btn-success",
            click: (row) => {
              this.onDeleteRow(row);
            },
          },
        ],
      });
      this.columns = columns;
      this.onBindData();
    },

    onBindData() {
      if (this.$store.state.paramOrder) {
        if (this.$store.state.paramOrder.checkInfo) {
          let checkInfo = this.$store.state.paramOrder.checkInfo;
          this.$store.commit("setParamOrder", {});
          this.itemSource = checkInfo;
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    onDeleteRow(row) {
      _.each(this.itemSource, (item, i) => {
        if (_.isEqual(item, row)) {
          this.itemSource.splice(i, 1);
          return;
        }
      });
      this.$emit("checkInfo", this.itemSource);
    },
  },
};
</script>

<style lang="scss">
.znl_check_grid {
  background-color: rgba(0, 0, 0, 0.4);
  .baseStyleDefalut.base-flex-grid .el-table {
    border-left: 1px solid #e6e6e6;
  }
  .el-dialog__footer {
    padding: 0 !important;
  }
}
</style>
